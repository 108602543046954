// Chakra Imports
import {
  Button,
  chakra,
  Box,
  Avatar,
  Center,
  MenuDivider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Skeleton,
  useBreakpointValue,
  Stack,
  Portal,
  Icon,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
// Custom Components
import { SidebarResponsive } from '../Sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { adminRoutes as routes } from '../../routes';
import { AuthContext } from '../../providers/authContext';
import { FaRegBell } from 'react-icons/fa';
import LogOutmodal from '../Admin/LogOutModal';

const NotificationPopUp = () => {
  return (
    <chakra.span
      pos="relative"
      display="inline-block"
      verticalAlign={'text-top'}>
      <Icon
        boxSize={6}
        as={FaRegBell}
        viewBox="0 0 24 24"
        color={'white'}
        _hover={{
          cursor: 'pointer',
        }}
      />
      <chakra.span
        pos="absolute"
        top="-1px"
        right="-1px"
        px={2}
        py={1}
        fontSize="xs"
        fontWeight="bold"
        lineHeight="none"
        color="red.100"
        transform="translate(50%,-50%)"
        bg="red.600"
        rounded="full">
        2
      </chakra.span>
    </chakra.span>
  );
};

export default function HeaderLinks(props) {
  const { variant, children, fixed, userProfile, userLoading, ...rest } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  //console.log('Admin links props: ', props);
  const isMobile = useBreakpointValue({
    sm: true,
    md: false,
  });

  return (
    <Box w="100%">
      <Stack
        pe={{ sm: '0px', md: '0px' }}
        w={{ sm: '100%' }}
        justifyContent={{ sm: 'space-between', xl: 'flex-end' }}
        alignItems="center"
        direction={'row'}>
        <SidebarResponsive
          logoText={props.logoText}
          secondary={props.secondary}
          routes={routes}
          bikletaPointsState={props.bikletaPointsState}
          onModalOpen={props.onModalOpen}
          {...rest}
        />
        <Stack
          direction={'row'}
          spacing={6}
          mb={{ sm: '20px', md: '0px' }}
          align="center">
          <Menu ms="0px" px="0px">
            <MenuButton
              as={Button}
              rounded={'none'}
              cursor={'pointer'}
              h="fit-content"
              bgColor={'transparent'}
              rightIcon={<ChevronDownIcon />}
              minW={0}>
              <Stack
                direction={'row'}
                spacing={4}
                my={2}
                align={'center'}
                textAlign="left">
                <Skeleton isLoaded={!userLoading} rounded="full">
                  <Avatar
                    size={isMobile ? 'sm' : 'md'}
                    src={userProfile?.profileImage}
                    borderWidth={1.5}
                    borderColor={'white'}
                  />
                </Skeleton>
                <Stack direction={'column'} spacing={1} fontSize={'small'} pr={4}>
                  <Skeleton isLoaded={!userLoading}>
                    <Text fontWeight={600}>{userProfile?.firstname}</Text>
                  </Skeleton>
                  <Skeleton isLoaded={!userLoading}>
                    <Text fontWeight={500} color={'gray.500'}>Administrador</Text>
                  </Skeleton>
                </Stack>
              </Stack>
            </MenuButton>
            <Portal>
              <MenuList
                alignItems={'center'}
                zIndex={999}
                bgColor="backgroundColor.100">
                <br />
                <Center>
                  <Avatar size={'2xl'} src={userProfile?.profileImage} />
                </Center>
                <br />
                <Skeleton isLoaded={userProfile?.firstname}>
                  <Center>
                    <Text fontSize={'md'} fontWeight="bold">
                      {userProfile?.firstname}
                    </Text>
                  </Center>
                </Skeleton>
                <MenuDivider />
                <MenuItem onClick={onOpen}>Salir</MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Stack>
      </Stack>
      <LogOutmodal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
