import { countryIcons } from './../images/index';

export const countries: {
  name: string;
  number: string;
  value: keyof typeof countryIcons | any;
  code: string;
  emoji: string;
  unicode: string;
  image: string;
}[] = [
  {
    name: 'Afghanistan',
    code: 'AF',
    emoji: '🇦🇫',
    unicode: 'U+1F1E6 U+1F1EB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg',
    value: 'afghanistan',
    number: '93',
  },
  {
    name: 'Albania',
    code: 'AL',
    emoji: '🇦🇱',
    unicode: 'U+1F1E6 U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg',
    value: 'albania',
    number: '355',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    emoji: '🇩🇿',
    unicode: 'U+1F1E9 U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg',
    value: 'algeria',
    number: '213',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    emoji: '🇦🇸',
    unicode: 'U+1F1E6 U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg',
    value: 'americanSamoa',
    number: '1684',
  },
  {
    name: 'Andorra',
    code: 'AD',
    emoji: '🇦🇩',
    unicode: 'U+1F1E6 U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg',
    value: 'andorra',
    number: '376',
  },
  {
    name: 'Angola',
    code: 'AO',
    emoji: '🇦🇴',
    unicode: 'U+1F1E6 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg',
    value: 'angola',
    number: '244',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    emoji: '🇦🇮',
    unicode: 'U+1F1E6 U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg',
    value: 'anguilla',
    number: '1264',
  },
  {
    name: 'Argentina',
    code: 'AR',
    emoji: '🇦🇷',
    unicode: 'U+1F1E6 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg',
    value: 'argentina',
    number: '54',
  },
  {
    name: 'Armenia',
    code: 'AM',
    emoji: '🇦🇲',
    unicode: 'U+1F1E6 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg',
    value: 'armenia',
    number: '374',
  },
  {
    name: 'Aruba',
    code: 'AW',
    emoji: '🇦🇼',
    unicode: 'U+1F1E6 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg',
    value: 'aruba',
    number: '297',
  },
  {
    name: 'Australia',
    code: 'AU',
    emoji: '🇦🇺',
    unicode: 'U+1F1E6 U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg',
    value: 'australia',
    number: '61',
  },
  {
    name: 'Austria',
    code: 'AT',
    emoji: '🇦🇹',
    unicode: 'U+1F1E6 U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg',
    value: 'austria',
    number: '43',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    emoji: '🇦🇿',
    unicode: 'U+1F1E6 U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg',
    value: 'azerbaijan',
    number: '994',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    emoji: '🇧🇸',
    unicode: 'U+1F1E7 U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg',
    value: 'bahamas',
    number: '1242',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    emoji: '🇧🇭',
    unicode: 'U+1F1E7 U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
    value: 'bahrain',
    number: '973',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    emoji: '🇧🇩',
    unicode: 'U+1F1E7 U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg',
    value: 'bangladesh',
    number: '880',
  },
  {
    name: 'Barbados',
    code: 'BB',
    emoji: '🇧🇧',
    unicode: 'U+1F1E7 U+1F1E7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg',
    value: 'barbados',
    number: '1246',
  },
  {
    name: 'Belarus',
    code: 'BY',
    emoji: '🇧🇾',
    unicode: 'U+1F1E7 U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg',
    value: 'belarus',
    number: '375',
  },
  {
    name: 'Belgium',
    code: 'BE',
    emoji: '🇧🇪',
    unicode: 'U+1F1E7 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg',
    value: 'belgium',
    number: '32',
  },
  {
    name: 'Belize',
    code: 'BZ',
    emoji: '🇧🇿',
    unicode: 'U+1F1E7 U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg',
    value: 'belize',
    number: '501',
  },
  {
    name: 'Benin',
    code: 'BJ',
    emoji: '🇧🇯',
    unicode: 'U+1F1E7 U+1F1EF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg',
    value: 'benin',
    number: '229',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    emoji: '🇧🇲',
    unicode: 'U+1F1E7 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg',
    value: 'bermuda',
    number: '1441',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    emoji: '🇧🇹',
    unicode: 'U+1F1E7 U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg',
    value: 'bhutan',
    number: '975',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    emoji: '🇧🇴',
    unicode: 'U+1F1E7 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg',
    value: 'bolivia',
    number: '591',
  },
  {
    name: 'Botswana',
    code: 'BW',
    emoji: '🇧🇼',
    unicode: 'U+1F1E7 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg',
    value: 'botswana',
    number: '267',
  },
  {
    name: 'Brazil',
    code: 'BR',
    emoji: '🇧🇷',
    unicode: 'U+1F1E7 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg',
    value: 'brazil',
    number: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    emoji: '🇮🇴',
    unicode: 'U+1F1EE U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg',
    value: 'britishIndianOceanTerritory',
    number: '246',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    emoji: '🇧🇬',
    unicode: 'U+1F1E7 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg',
    value: 'bulgaria',
    number: '359',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    emoji: '🇧🇫',
    unicode: 'U+1F1E7 U+1F1EB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg',
    value: 'burkinaFaso',
    number: '226',
  },
  {
    name: 'Burundi',
    code: 'BI',
    emoji: '🇧🇮',
    unicode: 'U+1F1E7 U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg',
    value: 'burundi',
    number: '257',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    emoji: '🇰🇭',
    unicode: 'U+1F1F0 U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg',
    value: 'cambodia',
    number: '855',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    emoji: '🇨🇲',
    unicode: 'U+1F1E8 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg',
    value: 'cameroon',
    number: '237',
  },
  {
    name: 'Canada',
    code: 'CA',
    emoji: '🇨🇦',
    unicode: 'U+1F1E8 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg',
    value: 'canada',
    number: '1',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    emoji: '🇨🇻',
    unicode: 'U+1F1E8 U+1F1FB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg',
    value: 'capeVerde',
    number: '238',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    emoji: '🇰🇾',
    unicode: 'U+1F1F0 U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg',
    value: 'caymanIslands',
    number: '345',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    emoji: '🇨🇫',
    unicode: 'U+1F1E8 U+1F1EB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg',
    value: 'centralAfricanRepublic',
    number: '236',
  },
  {
    name: 'Chad',
    code: 'TD',
    emoji: '🇹🇩',
    unicode: 'U+1F1F9 U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg',
    value: 'chad',
    number: '235',
  },
  {
    name: 'Chile',
    code: 'CL',
    emoji: '🇨🇱',
    unicode: 'U+1F1E8 U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg',
    value: 'chile',
    number: '56',
  },
  {
    name: 'China',
    code: 'CN',
    emoji: '🇨🇳',
    unicode: 'U+1F1E8 U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg',
    value: 'china',
    number: '86',
  },
  {
    name: 'Colombia',
    code: 'CO',
    emoji: '🇨🇴',
    unicode: 'U+1F1E8 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg',
    value: 'colombia',
    number: '57',
  },
  {
    name: 'Comoros',
    code: 'KM',
    emoji: '🇰🇲',
    unicode: 'U+1F1F0 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg',
    value: 'comoros',
    number: '269',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    emoji: '🇨🇷',
    unicode: 'U+1F1E8 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg',
    value: 'costaRica',
    number: '506',
  },
  {
    name: 'Croatia',
    code: 'HR',
    emoji: '🇭🇷',
    unicode: 'U+1F1ED U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg',
    value: 'croatia',
    number: '385',
  },
  {
    name: 'Cuba',
    code: 'CU',
    emoji: '🇨🇺',
    unicode: 'U+1F1E8 U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg',
    value: 'cuba',
    number: '53',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    emoji: '🇨🇾',
    unicode: 'U+1F1E8 U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg',
    value: 'cyprus',
    number: '357',
  },
  {
    name: 'Denmark',
    code: 'DK',
    emoji: '🇩🇰',
    unicode: 'U+1F1E9 U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg',
    value: 'denmark',
    number: '45',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    emoji: '🇩🇯',
    unicode: 'U+1F1E9 U+1F1EF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg',
    value: 'djibouti',
    number: '253',
  },
  {
    name: 'Dominica',
    code: 'DM',
    emoji: '🇩🇲',
    unicode: 'U+1F1E9 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg',
    value: 'dominica',
    number: '1767',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    emoji: '🇩🇴',
    unicode: 'U+1F1E9 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg',
    value: 'dominicanRepublic',
    number: '1849',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    emoji: '🇪🇨',
    unicode: 'U+1F1EA U+1F1E8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg',
    value: 'ecuador',
    number: '593',
  },
  {
    name: 'Egypt',
    code: 'EG',
    emoji: '🇪🇬',
    unicode: 'U+1F1EA U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
    value: 'egypt',
    number: '20',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    emoji: '🇸🇻',
    unicode: 'U+1F1F8 U+1F1FB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg',
    value: 'elSalvador',
    number: '503',
  },
  {
    name: 'England',
    code: 'ENGLAND',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    unicode: 'U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg',
    value: 'england',
    number: '44',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    emoji: '🇬🇶',
    unicode: 'U+1F1EC U+1F1F6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg',
    value: 'equatorialGuinea',
    number: '240',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    emoji: '🇪🇷',
    unicode: 'U+1F1EA U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg',
    value: 'eritrea',
    number: '291',
  },
  {
    name: 'España',
    code: 'ES',
    emoji: '🇪🇸',
    unicode: 'U+1F1EA U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg',
    value: 'spain',
    number: '34',
  },
  {
    name: 'Estonia',
    code: 'EE',
    emoji: '🇪🇪',
    unicode: 'U+1F1EA U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg',
    value: 'estonia',
    number: '372',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    emoji: '🇪🇹',
    unicode: 'U+1F1EA U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg',
    value: 'ethiopia',
    number: '251',
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    emoji: '🇫🇰',
    unicode: 'U+1F1EB U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg',
    value: 'falklandIslands',
    number: '500',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    emoji: '🇫🇴',
    unicode: 'U+1F1EB U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg',
    value: 'faroeIslands',
    number: '298',
  },
  {
    name: 'Fiji',
    code: 'FJ',
    emoji: '🇫🇯',
    unicode: 'U+1F1EB U+1F1EF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg',
    value: 'fiji',
    number: '679',
  },
  {
    name: 'Finland',
    code: 'FI',
    emoji: '🇫🇮',
    unicode: 'U+1F1EB U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg',
    value: 'finland',
    number: '358',
  },
  {
    name: 'France',
    code: 'FR',
    emoji: '🇫🇷',
    unicode: 'U+1F1EB U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg',
    value: 'france',
    number: '33',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    emoji: '🇬🇫',
    unicode: 'U+1F1EC U+1F1EB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg',
    value: 'frenchGuiana',
    number: '594',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    emoji: '🇵🇫',
    unicode: 'U+1F1F5 U+1F1EB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg',
    value: 'frenchPolynesia',
    number: '689',
  },
  {
    name: 'Gabon',
    code: 'GA',
    emoji: '🇬🇦',
    unicode: 'U+1F1EC U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg',
    value: 'gabon',
    number: '241',
  },
  {
    name: 'Gambia',
    code: 'GM',
    emoji: '🇬🇲',
    unicode: 'U+1F1EC U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg',
    value: 'gambia',
    number: '220',
  },
  {
    name: 'Georgia',
    code: 'GE',
    emoji: '🇬🇪',
    unicode: 'U+1F1EC U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg',
    value: 'georgia',
    number: '995',
  },
  {
    name: 'Germany',
    code: 'DE',
    emoji: '🇩🇪',
    unicode: 'U+1F1E9 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg',
    value: 'germany',
    number: '49',
  },
  {
    name: 'Ghana',
    code: 'GH',
    emoji: '🇬🇭',
    unicode: 'U+1F1EC U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg',
    value: 'ghana',
    number: '233',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    emoji: '🇬🇮',
    unicode: 'U+1F1EC U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg',
    value: 'gibraltar',
    number: '350',
  },
  {
    name: 'Greece',
    code: 'GR',
    emoji: '🇬🇷',
    unicode: 'U+1F1EC U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg',
    value: 'greece',
    number: '30',
  },
  {
    name: 'Greenland',
    code: 'GL',
    emoji: '🇬🇱',
    unicode: 'U+1F1EC U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg',
    value: 'greenland',
    number: '299',
  },
  {
    name: 'Grenada',
    code: 'GD',
    emoji: '🇬🇩',
    unicode: 'U+1F1EC U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg',
    value: 'grenada',
    number: '1473',
  },
  {
    name: 'Guam',
    code: 'GU',
    emoji: '🇬🇺',
    unicode: 'U+1F1EC U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg',
    value: 'guam',
    number: '1671',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    emoji: '🇬🇹',
    unicode: 'U+1F1EC U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg',
    value: 'guatemala',
    number: '502',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    emoji: '🇬🇬',
    unicode: 'U+1F1EC U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg',
    value: 'guernsey',
    number: '44',
  },
  {
    name: 'Guinea',
    code: 'GN',
    emoji: '🇬🇳',
    unicode: 'U+1F1EC U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg',
    value: 'guinea',
    number: '224',
  },
  {
    name: 'Guyana',
    code: 'GY',
    emoji: '🇬🇾',
    unicode: 'U+1F1EC U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg',
    value: 'guyana',
    number: '592',
  },
  {
    name: 'Haiti',
    code: 'HT',
    emoji: '🇭🇹',
    unicode: 'U+1F1ED U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg',
    value: 'haiti',
    number: '509',
  },
  {
    name: 'Honduras',
    code: 'HN',
    emoji: '🇭🇳',
    unicode: 'U+1F1ED U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg',
    value: 'honduras',
    number: '504',
  },
  {
    name: 'Hungary',
    code: 'HU',
    emoji: '🇭🇺',
    unicode: 'U+1F1ED U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg',
    value: 'hungary',
    number: '36',
  },
  {
    name: 'Iceland',
    code: 'IS',
    emoji: '🇮🇸',
    unicode: 'U+1F1EE U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg',
    value: 'iceland',
    number: '354',
  },
  {
    name: 'India',
    code: 'IN',
    emoji: '🇮🇳',
    unicode: 'U+1F1EE U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
    value: 'india',
    number: '91',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    emoji: '🇮🇩',
    unicode: 'U+1F1EE U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg',
    value: 'indonesia',
    number: '62',
  },
  {
    name: 'Iran',
    code: 'IR',
    emoji: '🇮🇷',
    unicode: 'U+1F1EE U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg',
    value: 'iran',
    number: '98',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    emoji: '🇮🇶',
    unicode: 'U+1F1EE U+1F1F6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg',
    value: 'iraq',
    number: '964',
  },
  {
    name: 'Ireland',
    code: 'IE',
    emoji: '🇮🇪',
    unicode: 'U+1F1EE U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg',
    value: 'ireland',
    number: '353',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    emoji: '🇮🇲',
    unicode: 'U+1F1EE U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg',
    value: 'isleOfMan',
    number: '44',
  },
  {
    name: 'Israel',
    code: 'IL',
    emoji: '🇮🇱',
    unicode: 'U+1F1EE U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg',
    value: 'israel',
    number: '972',
  },
  {
    name: 'Italy',
    code: 'IT',
    emoji: '🇮🇹',
    unicode: 'U+1F1EE U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg',
    value: 'italy',
    number: '39',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    emoji: '🇯🇲',
    unicode: 'U+1F1EF U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg',
    value: 'jamaica',
    number: '1876',
  },
  {
    name: 'Japan',
    code: 'JP',
    emoji: '🇯🇵',
    unicode: 'U+1F1EF U+1F1F5',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg',
    value: 'japan',
    number: '81',
  },
  {
    name: 'Jersey',
    code: 'JE',
    emoji: '🇯🇪',
    unicode: 'U+1F1EF U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg',
    value: 'jersey',
    number: '44',
  },
  {
    name: 'Jordan',
    code: 'JO',
    emoji: '🇯🇴',
    unicode: 'U+1F1EF U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg',
    value: 'jordan',
    number: '962',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    emoji: '🇰🇿',
    unicode: 'U+1F1F0 U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg',
    value: 'kazakhstan',
    number: '7',
  },
  {
    name: 'Kenya',
    code: 'KE',
    emoji: '🇰🇪',
    unicode: 'U+1F1F0 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg',
    value: 'kenya',
    number: '254',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    emoji: '🇰🇮',
    unicode: 'U+1F1F0 U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg',
    value: 'kiribati',
    number: '686',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    emoji: '🇽🇰',
    unicode: 'U+1F1FD U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg',
    value: 'kosovo',
    number: '383',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    emoji: '🇰🇼',
    unicode: 'U+1F1F0 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg',
    value: 'kuwait',
    number: '965',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    emoji: '🇰🇬',
    unicode: 'U+1F1F0 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg',
    value: 'kyrgyzstan',
    number: '996',
  },
  {
    name: 'Laos',
    code: 'LA',
    emoji: '🇱🇦',
    unicode: 'U+1F1F1 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg',
    value: 'laos',
    number: '856',
  },
  {
    name: 'Latvia',
    code: 'LV',
    emoji: '🇱🇻',
    unicode: 'U+1F1F1 U+1F1FB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg',
    value: 'latvia',
    number: '371',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    emoji: '🇱🇧',
    unicode: 'U+1F1F1 U+1F1E7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg',
    value: 'lebanon',
    number: '961',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    emoji: '🇱🇸',
    unicode: 'U+1F1F1 U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg',
    value: 'lesotho',
    number: '266',
  },
  {
    name: 'Liberia',
    code: 'LR',
    emoji: '🇱🇷',
    unicode: 'U+1F1F1 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg',
    value: 'liberia',
    number: '231',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    emoji: '🇱🇮',
    unicode: 'U+1F1F1 U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg',
    value: 'liechtenstein',
    number: '423',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    emoji: '🇱🇹',
    unicode: 'U+1F1F1 U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg',
    value: 'lithuania',
    number: '370',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    emoji: '🇱🇺',
    unicode: 'U+1F1F1 U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg',
    value: 'luxembourg',
    number: '352',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    emoji: '🇲🇬',
    unicode: 'U+1F1F2 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg',
    value: 'madagascar',
    number: '261',
  },
  {
    name: 'Malawi',
    code: 'MW',
    emoji: '🇲🇼',
    unicode: 'U+1F1F2 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg',
    value: 'malawi',
    number: '265',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    emoji: '🇲🇾',
    unicode: 'U+1F1F2 U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg',
    value: 'malaysia',
    number: '60',
  },
  {
    name: 'Maldives',
    code: 'MV',
    emoji: '🇲🇻',
    unicode: 'U+1F1F2 U+1F1FB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg',
    value: 'maldives',
    number: '960',
  },
  {
    name: 'Mali',
    code: 'ML',
    emoji: '🇲🇱',
    unicode: 'U+1F1F2 U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg',
    value: 'mali',
    number: '223',
  },
  {
    name: 'Malta',
    code: 'MT',
    emoji: '🇲🇹',
    unicode: 'U+1F1F2 U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg',
    value: 'malta',
    number: '356',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    emoji: '🇲🇶',
    unicode: 'U+1F1F2 U+1F1F6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg',
    value: 'martinique',
    number: '596',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    emoji: '🇲🇷',
    unicode: 'U+1F1F2 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg',
    value: 'mauritania',
    number: '222',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    emoji: '🇲🇺',
    unicode: 'U+1F1F2 U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg',
    value: 'mauritius',
    number: '230',
  },
  {
    name: 'Mexico',
    code: 'MX',
    emoji: '🇲🇽',
    unicode: 'U+1F1F2 U+1F1FD',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg',
    value: 'mexico',
    number: '52',
  },
  {
    name: 'Moldova',
    code: 'MD',
    emoji: '🇲🇩',
    unicode: 'U+1F1F2 U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg',
    value: 'moldova',
    number: '373',
  },
  {
    name: 'Monaco',
    code: 'MC',
    emoji: '🇲🇨',
    unicode: 'U+1F1F2 U+1F1E8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg',
    value: 'monaco',
    number: '377',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    emoji: '🇲🇳',
    unicode: 'U+1F1F2 U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg',
    value: 'mongolia',
    number: '976',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    emoji: '🇲🇪',
    unicode: 'U+1F1F2 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg',
    value: 'montenegro',
    number: '382',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    emoji: '🇲🇸',
    unicode: 'U+1F1F2 U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg',
    value: 'montserrat',
    number: '1664',
  },
  {
    name: 'Morocco',
    code: 'MA',
    emoji: '🇲🇦',
    unicode: 'U+1F1F2 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
    value: 'morocco',
    number: '212',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    emoji: '🇲🇿',
    unicode: 'U+1F1F2 U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg',
    value: 'mozambique',
    number: '258',
  },
  {
    name: 'Namibia',
    code: 'NA',
    emoji: '🇳🇦',
    unicode: 'U+1F1F3 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg',
    value: 'namibia',
    number: '264',
  },
  {
    name: 'Nauru',
    code: 'NR',
    emoji: '🇳🇷',
    unicode: 'U+1F1F3 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg',
    value: 'nauru',
    number: '674',
  },
  {
    name: 'Nepal',
    code: 'NP',
    emoji: '🇳🇵',
    unicode: 'U+1F1F3 U+1F1F5',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg',
    value: 'nepal',
    number: '977',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    emoji: '🇳🇱',
    unicode: 'U+1F1F3 U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg',
    value: 'netherlands',
    number: '31',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    emoji: '🇳🇿',
    unicode: 'U+1F1F3 U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg',
    value: 'newZealand',
    number: '64',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    emoji: '🇳🇮',
    unicode: 'U+1F1F3 U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg',
    value: 'nicaragua',
    number: '505',
  },
  {
    name: 'Niger',
    code: 'NE',
    emoji: '🇳🇪',
    unicode: 'U+1F1F3 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg',
    value: 'niger',
    number: '227',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    emoji: '🇳🇬',
    unicode: 'U+1F1F3 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg',
    value: 'nigeria',
    number: '234',
  },
  {
    name: 'Niue',
    code: 'NU',
    emoji: '🇳🇺',
    unicode: 'U+1F1F3 U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg',
    value: 'niue',
    number: '683',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    emoji: '🇳🇫',
    unicode: 'U+1F1F3 U+1F1EB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg',
    value: 'norfolkIsland',
    number: '672',
  },
  {
    name: 'Norway',
    code: 'NO',
    emoji: '🇳🇴',
    unicode: 'U+1F1F3 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg',
    value: 'norway',
    number: '47',
  },
  {
    name: 'Oman',
    code: 'OM',
    emoji: '🇴🇲',
    unicode: 'U+1F1F4 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg',
    value: 'oman',
    number: '968',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    emoji: '🇵🇰',
    unicode: 'U+1F1F5 U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg',
    value: 'pakistan',
    number: '92',
  },
  {
    name: 'Palau',
    code: 'PW',
    emoji: '🇵🇼',
    unicode: 'U+1F1F5 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg',
    value: 'palau',
    number: '680',
  },
  {
    name: 'Panama',
    code: 'PA',
    emoji: '🇵🇦',
    unicode: 'U+1F1F5 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg',
    value: 'panama',
    number: '507',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    emoji: '🇵🇬',
    unicode: 'U+1F1F5 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg',
    value: 'papuaNewGuinea',
    number: '675',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    emoji: '🇵🇾',
    unicode: 'U+1F1F5 U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg',
    value: 'paraguay',
    number: '595',
  },
  {
    name: 'Peru',
    code: 'PE',
    emoji: '🇵🇪',
    unicode: 'U+1F1F5 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg',
    value: 'peru',
    number: '51',
  },
  {
    name: 'Philippines',
    code: 'PH',
    emoji: '🇵🇭',
    unicode: 'U+1F1F5 U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg',
    value: 'philippines',
    number: '63',
  },
  {
    name: 'Poland',
    code: 'PL',
    emoji: '🇵🇱',
    unicode: 'U+1F1F5 U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg',
    value: 'poland',
    number: '48',
  },
  {
    name: 'Portugal',
    code: 'PT',
    emoji: '🇵🇹',
    unicode: 'U+1F1F5 U+1F1F9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg',
    value: 'portugal',
    number: '351',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    emoji: '🇵🇷',
    unicode: 'U+1F1F5 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg',
    value: 'puertoRico',
    number: '1939',
  },
  {
    name: 'Qatar',
    code: 'QA',
    emoji: '🇶🇦',
    unicode: 'U+1F1F6 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg',
    value: 'qatar',
    number: '974',
  },
  {
    name: 'Romania',
    code: 'RO',
    emoji: '🇷🇴',
    unicode: 'U+1F1F7 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg',
    value: 'romania',
    number: '40',
  },
  {
    name: 'Russia',
    code: 'RU',
    emoji: '🇷🇺',
    unicode: 'U+1F1F7 U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg',
    value: 'russia',
    number: '7',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    emoji: '🇷🇼',
    unicode: 'U+1F1F7 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg',
    value: 'rwanda',
    number: '250',
  },
  {
    name: 'Samoa',
    code: 'WS',
    emoji: '🇼🇸',
    unicode: 'U+1F1FC U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg',
    value: 'samoa',
    number: '685',
  },
  {
    name: 'San Marino',
    code: 'SM',
    emoji: '🇸🇲',
    unicode: 'U+1F1F8 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg',
    value: 'sanMarino',
    number: '378',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    emoji: '🇸🇦',
    unicode: 'U+1F1F8 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
    value: 'saudiArabia',
    number: '966',
  },
  {
    name: 'Scotland',
    code: 'SCOTLAND',
    emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    unicode: 'U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg',
    value: 'scotland',
    number: '44',
  },
  {
    name: 'Senegal',
    code: 'SN',
    emoji: '🇸🇳',
    unicode: 'U+1F1F8 U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg',
    value: 'senegal',
    number: '221',
  },
  {
    name: 'Serbia',
    code: 'RS',
    emoji: '🇷🇸',
    unicode: 'U+1F1F7 U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg',
    value: 'serbia',
    number: '381',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    emoji: '🇸🇨',
    unicode: 'U+1F1F8 U+1F1E8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg',
    value: 'seychelles',
    number: '248',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    emoji: '🇸🇱',
    unicode: 'U+1F1F8 U+1F1F1',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg',
    value: 'sierraLeone',
    number: '232',
  },
  {
    name: 'Singapore',
    code: 'SG',
    emoji: '🇸🇬',
    unicode: 'U+1F1F8 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg',
    value: 'singapore',
    number: '65',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    emoji: '🇸🇰',
    unicode: 'U+1F1F8 U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg',
    value: 'slovakia',
    number: '421',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    emoji: '🇸🇮',
    unicode: 'U+1F1F8 U+1F1EE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg',
    value: 'slovenia',
    number: '386',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    emoji: '🇸🇧',
    unicode: 'U+1F1F8 U+1F1E7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg',
    value: 'solomonIslands',
    number: '677',
  },
  {
    name: 'Somalia',
    code: 'SO',
    emoji: '🇸🇴',
    unicode: 'U+1F1F8 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg',
    value: 'somalia',
    number: '252',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    emoji: '🇿🇦',
    unicode: 'U+1F1FF U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg',
    value: 'southAfrica',
    number: '27',
  },
  {
    name: 'South Korea',
    code: 'KR',
    emoji: '🇰🇷',
    unicode: 'U+1F1F0 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg',
    value: 'southKorea',
    number: '82',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    emoji: '🇸🇸',
    unicode: 'U+1F1F8 U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg',
    value: 'southSudan',
    number: '211',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    emoji: '🇱🇰',
    unicode: 'U+1F1F1 U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg',
    value: 'sriLanka',
    number: '94',
  },
  {
    name: 'Sudan',
    code: 'SD',
    emoji: '🇸🇩',
    unicode: 'U+1F1F8 U+1F1E9',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg',
    value: 'sudan',
    number: '249',
  },
  {
    name: 'Suriname',
    code: 'SR',
    emoji: '🇸🇷',
    unicode: 'U+1F1F8 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg',
    value: 'suriname',
    number: '597',
  },
  {
    name: 'Sweden',
    code: 'SE',
    emoji: '🇸🇪',
    unicode: 'U+1F1F8 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg',
    value: 'sweden',
    number: '46',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    emoji: '🇨🇭',
    unicode: 'U+1F1E8 U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg',
    value: 'switzerland',
    number: '41',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    emoji: '🇹🇼',
    unicode: 'U+1F1F9 U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg',
    value: 'taiwan',
    number: '886',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    emoji: '🇹🇯',
    unicode: 'U+1F1F9 U+1F1EF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg',
    value: 'tajikistan',
    number: '992',
  },
  {
    name: 'Thailand',
    code: 'TH',
    emoji: '🇹🇭',
    unicode: 'U+1F1F9 U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg',
    value: 'thailand',
    number: '66',
  },
  {
    name: 'Togo',
    code: 'TG',
    emoji: '🇹🇬',
    unicode: 'U+1F1F9 U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg',
    value: 'togo',
    number: '228',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    emoji: '🇹🇰',
    unicode: 'U+1F1F9 U+1F1F0',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg',
    value: 'tokelau',
    number: '690',
  },
  {
    name: 'Tonga',
    code: 'TO',
    emoji: '🇹🇴',
    unicode: 'U+1F1F9 U+1F1F4',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg',
    value: 'tonga',
    number: '676',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    emoji: '🇹🇳',
    unicode: 'U+1F1F9 U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
    value: 'tunisia',
    number: '216',
  },
  {
    name: 'Turkey',
    code: 'TR',
    emoji: '🇹🇷',
    unicode: 'U+1F1F9 U+1F1F7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg',
    value: 'turkey',
    number: '90',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    emoji: '🇹🇲',
    unicode: 'U+1F1F9 U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg',
    value: 'turkmenistan',
    number: '993',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    emoji: '🇹🇻',
    unicode: 'U+1F1F9 U+1F1FB',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg',
    value: 'tuvalu',
    number: '688',
  },
  {
    name: 'Uganda',
    code: 'UG',
    emoji: '🇺🇬',
    unicode: 'U+1F1FA U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg',
    value: 'uganda',
    number: '256',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    emoji: '🇺🇦',
    unicode: 'U+1F1FA U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg',
    value: 'ukraine',
    number: '380',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
    value: 'unitedArabEmirates',
    number: '971',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    emoji: '🇬🇧',
    unicode: 'U+1F1EC U+1F1E7',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg',
    value: 'unitedKingdom',
    number: '44',
  },
  {
    name: 'United States',
    code: 'US',
    emoji: '🇺🇸',
    unicode: 'U+1F1FA U+1F1F8',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
    value: 'unitedStates',
    number: '1',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    emoji: '🇺🇾',
    unicode: 'U+1F1FA U+1F1FE',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg',
    value: 'uruguay',
    number: '598',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    emoji: '🇺🇿',
    unicode: 'U+1F1FA U+1F1FF',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg',
    value: 'uzbekistan',
    number: '998',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    emoji: '🇻🇺',
    unicode: 'U+1F1FB U+1F1FA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg',
    value: 'vanuatu',
    number: '678',
  },
  {
    name: 'Venezuela',
    code: 'VE',
    emoji: '🇻🇪',
    unicode: 'U+1F1FB U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg',
    value: 'venezuela',
    number: '58',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    emoji: '🇻🇳',
    unicode: 'U+1F1FB U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg',
    value: 'vietnam',
    number: '84',
  },
  {
    name: 'Wales',
    code: 'WALES',
    emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    unicode: 'U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg',
    value: 'wales',
    number: '44',
  },
  {
    name: 'Yemen',
    code: 'YE',
    emoji: '🇾🇪',
    unicode: 'U+1F1FE U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg',
    value: 'yemen',
    number: '967',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    emoji: '🇿🇲',
    unicode: 'U+1F1FF U+1F1F2',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg',
    value: 'zambia',
    number: '260',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    emoji: '🇿🇼',
    unicode: 'U+1F1FF U+1F1FC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg',
    value: 'zimbabwe',
    number: '263',
  },
];
