import {
    Text,
    Box,
    SimpleGrid,
    GridItem,
    FormControl,
    Input,
    Select,
    Stack,
    Wrap,
    WrapItem,
    Tag,
    Image,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    useToast,
    FormErrorMessage,
    Checkbox,
    FormLabel,
    Icon,
    ModalOverlay,
    Popover,
    Menu,
    PopoverTrigger,
    MenuButton,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    Center,
    Spinner
} from "@chakra-ui/react";
import { Field, FormikProvider, getIn, useFormik } from 'formik';
import { useStore } from "../../../utils/useStore";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { daysArray, PointTypes } from "../../../utils/constants";
import { useNavigate } from 'react-router-dom';
import InputData from "./inputdata";
import MapComponent from '../../../extra/MapComponent';
import { Marker } from '@react-google-maps/api';
import CustomField from "../../../components/Admin/FormSection/customField";
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { UploadIcon } from "../../../components/Icons/Icons";
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { BikeIcon, FlagLocationIcon, RunnerIcon, ToolIcon, CarretArrowDownIcon } from '../../../components/Icons/Icons';
import { IoMdAdd, IoMdRemove, IoIosArrowDown, IoIosBeer } from "react-icons/io";
import { HiArrowLeft } from "react-icons/hi";
import { useCommerceProfileViewController } from "./commerce-profile.controller";
import { useFormSectionController } from "../../../components/Admin/FormSection/formStepper.controller";
import { TbBeer, TbCoffee } from "react-icons/tb";
import { MdRestaurant } from "react-icons/md";


const ProfileEdit: React.FC = ({
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { activePoint } = useStore();
    const navigate = useNavigate();
    const controller = useCommerceProfileViewController();
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleSubmit = async values => {
        try {
            const openDayValidation = values?.workTime.some((item) => item.opens === true && item.openTime == undefined && item.closeTime == undefined);
            await controller.submitEditedData(activePoint._id, values);
            toast({
                title: 'Cambios realizados',
                position: 'top-right',
                status: 'success',
                duration: 2500,
            });
            navigate('/admin/commerce-profile')
        } catch (error) {
            throw new Error(error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const EditFormSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Demasiado corto!')
            .max(50, 'Demasiado largo!')
            .required('Ingrese el nombre del Negocio'),
        email: Yup.string()
            .email('Correo invalido!')
            .required('Debe colocar un correo'),
        phone: Yup.string(),
        phoneRegion: Yup.string(),
        web: Yup.string(),
        instagram: Yup.string(),
        facebook: Yup.string(),
        tiktok: Yup.string(),
        googleBusiness: Yup.string(),
        images: Yup.array(),
        type: Yup.string()
            .min(2, 'Demasiado corto!')
            .max(50, 'Demasiado largo!')
            .required('Seleccione el tipo de Negocio'),
        point: Yup.object().shape({
            lat: Yup.number().required(
                'Complete los datos de Dirección - Punto en el mapa'
            ),
            lng: Yup.number().required(
                'Complete los datos de Dirección - Punto en el mapa'
            ),
        }),
        address: Yup.string().required('Complete los datos de Dirección'),
    });

    const formik = useFormik({
        initialValues: activePoint && {
            name: activePoint.name,
            type: activePoint.type,
            email: activePoint.email,
            phone: activePoint?.phone || '',
            phoneRegion: activePoint?.phoneRegion || '',
            address: activePoint.address,
            point: activePoint.point,
            web: activePoint.web,
            instagram: activePoint.instagram,
            facebook: activePoint.facebook,
            tiktok: activePoint.tiktok,
            googleBusiness: activePoint.googleBusiness,
            workTime: activePoint.workTime,
            images: activePoint.images,
            newPhotos: [],
        },
        validationSchema: EditFormSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    useEffect(() => {
        const openDayValidation = formik.values?.workTime.some((item) => {
            if (item.opens === true) {
                if (item.openTime === undefined || item.closeTime === undefined) {
                    return true;
                }
                return false;
            }
            return false;
        });
        if (openDayValidation === true) {
            setIsSubmitting(false);
        } else {
            if (formik.dirty || formik.isSubmitting) {
                setIsSubmitting(true);
            } else {
                setIsSubmitting(false);
            }
        }
    }, [formik.dirty, formik.isSubmitting, formik.values?.workTime]);

    return (
        <Stack>
            <Box display="flex" flexDirection={'row'} alignContent={'center'} mb={5} ml={2}>
                <Button w={'24px'} h={'24px'} background={'none'} onClick={onOpen}>
                    <Icon as={HiArrowLeft} boxSize={6} />
                </Button>
                <Text mr={5}>Editar Perfil</Text>
            </Box>
            <SimpleGrid columns={2} spacing={10}>
                <SimpleGrid columns={1}>
                    <FormProfileEdit formik={formik} />
                    <ScheduleEditingSection formik={formik} />
                </SimpleGrid>
                <ImagesEditSection formik={formik} />
            </SimpleGrid>
            <SimpleGrid pt={20} width={'min-content'} display={'flex'} flexDirection={'row'} alignSelf={'flex-end'}>
                <Button onClick={onOpen}>Cancelar</Button>
                <Button ml={5} mr={10} textColor={isSubmitting ? 'black' : 'white'} isDisabled={!isSubmitting} backgroundColor={isSubmitting ? '#FFC700' : 'rgba(255, 240, 255, 0.2)'} onClick={() => handleSubmit(formik.values)}>Guardar Cambios</Button>
            </SimpleGrid>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent style={{ backgroundColor: '#2B2930', borderRadius: 28 }}>
                    <ModalHeader fontWeight="md" >Descartar Cambios</ModalHeader>
                    <ModalBody paddingTop={0} paddingBottom={0}>
                        <Text fontSize={14} mt={2}>
                            ¿Confirmas que quieres descartar los cambios?
                            Las modificaciones que hayas realizado no se aplicarán
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} mr={3} rounded={'full'} fontWeight="md" fontSize={14}>
                            Cancelar
                        </Button>
                        <Button colorScheme="brand" onClick={() => handleBack()} mr={3} rounded={'full'} fontWeight="md" fontSize={14}>
                            Aceptar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
};
export default ProfileEdit;


const FormProfileEdit = ({ formik }) => {

    const setStyle = (dataInput) => {
        const styling = {
            container: {
                maxWidth: dataInput?.name === 'phoneRegion' ? '25%' : null,
                minWidth: dataInput?.name === 'phone' ? '65%' : null,
                display: 'flex',
                justifyContent: 'space-between',
            },
            label: {
                position: "absolute",
                top: "-0.6em",
                left: "1.2em",
                paddingInline: 5,
                color: "gray.500",
                backgroundColor: "#313149",
                fontSize: "sm",
                transition: "0.2s ease all",
                fontWeight: "md",
                zIndex: 2
            }
        }
        return styling
    }

    return (
        <FormikProvider value={formik}>
            <Box>
                <SimpleGrid display={'grid'}>
                    <GridItem mt={[5, null, 0]} colSpan={2}>
                        <Stack px={4} p={[null, 6]} spacing={6}>
                            <SimpleGrid spacing={4}>
                                {InputData.businessnameInput.map((item, index) => <CustomField key={index} dataInput={item} styling={setStyle(null)} filetype={Input} />)}
                                <CategoriesEdit formik={formik} />
                                <Box mt={10}>
                                    <Text>Informacion de contacto</Text>
                                    {InputData.dataInput.map((dataInput, index) => (
                                        index !== 1 ?
                                            <CustomField key={index} dataInput={dataInput} styling={setStyle(null)} filetype={Input} />
                                            :
                                            <Box
                                                key={index}
                                                display="flex"
                                                justifyContent={'space-between'}
                                                flexDirection={'row'}
                                                height={'-moz-min-content'}>
                                                {dataInput.phone.map((dataInput, index) => (
                                                    <CustomField
                                                        key={index}
                                                        dataInput={{ ...dataInput, formik: formik.values?.phoneRegion }}
                                                        styling={setStyle(dataInput)}
                                                        filetype={dataInput?.name === 'phoneRegion' ? Select : Input}
                                                    />
                                                ))}
                                            </Box>
                                    ))}
                                    <MapSeccion formik={formik} />
                                </Box>
                            </SimpleGrid>
                        </Stack>
                    </GridItem>

                </SimpleGrid>
            </Box>
        </FormikProvider>
    );
};



const CategoriesEdit = ({ formik }) => {
    const { activePoint } = useStore();
    const [selectedTags, setSelectedTags] = useState(formik.values?.type || []);
    const handleTagClick = (tag) => {
        if (selectedTags?.find(t => t === tag.value)) {
            setSelectedTags((prevTags) => prevTags.filter((value) => value !== tag.value));
            formik.setFieldValue('type', selectedTags.filter((value) => value !== tag.value));
        } else {
            setSelectedTags((prevTags) => [...prevTags, tag.value]);
            formik.setFieldValue('type', [...selectedTags, tag.value]);
        }
    };

    useEffect(() => {
        if (activePoint) {
            setSelectedTags(formik.values?.type)
        }
    }, [activePoint])

    return (
        <Box mt={5}>
            <Text mb={8}>Categorías del negocio</Text>
            <Wrap p={2} spacing={4}>
                {PointTypes.map((tag, index) => (
                    <WrapItem key={index}>
                        <Tag
                            size="lg"
                            variant="solid"
                            background={selectedTags.find(t => t === tag.value) ? '#D8D7E2' : 'trasparent'}
                            cursor="pointer"
                            onClick={() => handleTagClick(tag)}
                            textColor={selectedTags.find(t => t === tag.value) ? 'black' : 'white'}
                            border={selectedTags.find(t => t === tag.value) ? '1px solid #D8D7E2' : '1px solid #D8D7E2'}
                        >
                            {tag.value === 'BikeWorkshop' && <ToolIcon fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} boxSize={5} mr={2} />}
                            {tag.value === 'clothesShop' && <RunnerIcon fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} boxSize={5} mr={2} />}
                            {tag.value === 'tourismPoint' && <FlagLocationIcon fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} boxSize={5} mr={2} />}
                            {tag.value === 'BikeStore' && <BikeIcon fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} boxSize={5} mr={2} />}
                            {tag.value === 'coffieBar' && <TbCoffee fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} color={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} style={{ marginRight: '7px', width: '20px', height: '20px' }} />}
                            {tag.value === 'bar' && <IoIosBeer fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} color={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} style={{ marginRight: '7px', width: '20px', height: '20px' }} />}
                            {tag.value === 'restaurant' && <MdRestaurant fill={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} color={selectedTags.find(t => t === tag.value) ? 'black' : 'white'} style={{ marginRight: '7px', width: '20px', height: '20px' }} />}
                            {tag.label}
                        </Tag>
                    </WrapItem>
                ))}
            </Wrap>
        </Box>
    );
}




const MapSeccion = ({ formik }) => {
    const initRef = React.useRef();
    let debounceTimer;
    const controller = useFormSectionController();
    const debounce = (callback, time) => {
        window.clearTimeout(debounceTimer);
        debounceTimer = window.setTimeout(callback, time);
    };
    return (
        <SimpleGrid mt={10} columns={6} spacing={4} as={GridItem} colSpan={6}>
            <FormControl
                mb={5}
                as={GridItem}
                colSpan={{ sm: 6, md: 6 }}
                style={{ zIndex: 1000, position: "relative", }}
                isInvalid={getIn(formik.errors, 'address')}
                zIndex={1}
                isRequired>
                <Popover
                    closeOnBlur={false}
                    placement="top"
                    initialFocusRef={initRef}>
                    {({ isOpen, onClose }) => (
                        <Box w={'100%'}>
                            <Menu>
                                <PopoverTrigger>
                                    <MenuButton
                                        as={Button}
                                        rounded="lg"
                                        minW="100%"
                                        maxW="100%"
                                        size="lg"
                                        backgroundColor={'transparent'}
                                        border={'1px solid'}
                                        borderColor="#79747E"
                                        _hover={{ backgroundColor: 'transparent' }}
                                        _focusWithin={{ borderColor: 'brand.50' }}
                                        textAlign={'left'}
                                        fontWeight="normal"
                                        rightIcon={<ChevronDownIcon />}>
                                        <Text isTruncated>
                                            {formik.values?.address === ''
                                                ? 'Elija su ubicación...'
                                                : formik.values?.address}
                                        </Text>
                                    </MenuButton>
                                </PopoverTrigger>
                            </Menu>
                            <PopoverContent w={'47vw'}>
                                <PopoverHeader w="100%">
                                    <Input
                                        ref={initRef}
                                        type={'text'}
                                        placeholder="Buscar dirección..."
                                        size={'sm'}
                                        rounded={'md'}
                                        p={2}
                                        onChange={event =>
                                            debounce(() => {
                                                event.target.value !== '' &&
                                                    controller.setSearchQuery(
                                                        event.target.value
                                                    );
                                            }, 1000)
                                        }
                                    />
                                </PopoverHeader>
                                <PopoverBody
                                    maxH={'40vh'}
                                    minW={'100%'}
                                    overflowY="auto"
                                    p={0}
                                    bgColor="transparent"
                                    sx={{
                                        '&::-webkit-scrollbar': {
                                            width: '7px',
                                            borderRadius: '10px',
                                            backgroundColor: `rgba(0, 0, 0, 0.1)`,
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: `rgba(0, 0, 0, 0.4)`,
                                            borderRadius: '10px',
                                        },
                                    }}>
                                    {controller.loadingData ? (
                                        <Center my={4}>
                                            <Spinner size="md" />
                                        </Center>
                                    ) : !controller.placesSearchResults ? null : controller
                                        .placesSearchResults?.length > 0 ? (
                                        controller.placesSearchResults.map(
                                            (place, key) => (
                                                <Button
                                                    w={'100%'}
                                                    maxW={'100%'}
                                                    variant="ghost"
                                                    rounded={'none'}
                                                    justifyContent={'flex-start'}
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            'address',
                                                            place.formatted_address
                                                        );

                                                        formik.setFieldValue(
                                                            'point',
                                                            place.geometry.location
                                                        );
                                                        onClose();
                                                    }}
                                                    key={'TypeItem-' + key}
                                                    isTruncated>
                                                    <Text isTruncated fontWeight={'normal'}>
                                                        {place.formatted_address}
                                                    </Text>
                                                </Button>
                                            )
                                        )
                                    ) : (
                                        <Text my={4}>Sin resultados</Text>
                                    )}
                                </PopoverBody>
                            </PopoverContent>
                        </Box>
                    )}
                </Popover>
                {getIn(formik.errors, 'type') ? (
                    <FormErrorMessage fontSize={'x-small'}>
                        {getIn(formik.errors, 'type')}
                    </FormErrorMessage>
                ) : null}
                <FormLabel
                    style={{
                        position: "absolute",
                        top: "-0.7em",
                        left: "1.2em",
                        paddingInline: 5,
                        color: "gray.500",
                        backgroundColor: "#313149",
                        fontSize: "sm",
                        transition: "0.2s ease all",
                        fontWeight: "md",
                        zIndex: 2
                    }}
                    pointerEvents="none"
                    htmlFor="name"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    Ubicación
                </FormLabel>
            </FormControl>
            <FormControl
                as={GridItem}
                colSpan={{ sm: 6, md: 6 }}
                style={{ borderRadius: 50 }}>
                <Box
                    borderRadius={20}
                    overflow="hidden"
                    borderWidth={1.5}
                    borderColor="gray.500">
                    <MapComponent
                        height={'25vh'}
                        center={
                            formik.values?.point.lat && formik.values?.point.lng
                                ? {
                                    lat: formik.values?.point.lat,
                                    lng: formik.values?.point.lng,
                                }
                                : null
                        }
                        zoom={
                            formik.values?.point.lat && formik.values?.point.lng
                                ? 15
                                : null
                        }
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            styles: [
                                {
                                    featureType: 'poi',
                                    stylers: [{ visibility: 'off' }],
                                },
                                {
                                    featureType: 'poi.business',
                                    stylers: [{ visibility: 'off' }],
                                },
                                {
                                    featureType: 'poi',
                                    elementType: 'labels',
                                    stylers: [{ visibility: 'off' }],
                                },
                                {
                                    featureType: 'poi.business',
                                    elementType: 'labels',
                                    stylers: [{ visibility: 'off' }],
                                },
                            ],
                        }}>
                        {formik.values?.point.lat && formik.values?.point.lng && (
                            <Marker
                                key={'New-BP'}
                                position={{
                                    lat: formik.values?.point.lat,
                                    lng: formik.values?.point.lng,
                                }}
                            />
                        )}
                    </MapComponent>
                </Box>
            </FormControl>
        </SimpleGrid>
    )
}

const ImagesEditSection = ({ formik }) => {
    const { activePoint } = useStore();
    const [images, setImages] = useState(formik.values?.images || []);

    const [newPhotos, setNewPhotos] = useState(formik.values?.newPhotos || []);
    const handleDeleteImages = (image) => {
        setImages((prevImages) => prevImages.filter((value) => {
            return value !== image
        }));
        formik.setFieldValue('images', images.filter((value) => value !== image));
        formik.setFieldValue('newPhotos',
            newPhotos.filter((value) =>
                URL.createObjectURL(value.file) !== image));
    };

    useEffect(() => {
        setImages(formik.values?.images || []);
    }, [activePoint]);

    return (
        <Stack display={'flex'} alignItems={'center'}>
            <Stack>
                <Text mb={2}>Fotos de tu negocio</Text>
                <Text mb={2} fontSize={'sm'}>
                    Muestra fotos atractivas de lo que tu negocio ofrece <br />
                    Formato adimitido: JPG o PNG. Máximo: 5 fotos
                </Text>
                <Stack>
                    {
                        images.length < 5
                        &&
                        <UploadPhotosModal key={'UploadPhotosModal'} formik={formik} imageUpdate={setImages} />
                    }
                    {images &&
                        images.map((image, index) => (
                            <Box
                                key={index}
                                position="relative"
                                borderRadius="md"
                                overflow="hidden"
                                display={'flex'}
                                paddingBlock={2.5}
                                justifyContent={'right'}
                            >
                                <Button
                                    zIndex={11}
                                    position={'absolute'}
                                    mt={2}
                                    mr={2}
                                    backgroundColor={'#D8D7E2'}
                                    borderRadius={50}
                                    height={'2.8em'}
                                    _hover={{ boxShadow: 'none' }}
                                    onClick={() => handleDeleteImages(image)}
                                >
                                    <CloseIcon color={'black'} />
                                </Button>
                                <Image
                                    position={'relative'}
                                    src={image}
                                    alt={'bussines-image'}
                                    borderRadius={20}
                                    maxW={'390px'}
                                    maxH={'280px'}
                                    zIndex={10}
                                />
                            </Box>
                        ))}
                </Stack>
            </Stack>
        </Stack>
    )
}

const UploadPhotosModal = ({ formik, imageUpdate }) => {
    const toast = useToast();
    const [imageArray, setImageArray] = useState<any[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleUploadPhtos = (imageArray) => {
        const urls = imageArray.map(file => URL.createObjectURL(file.file));
        const files = imageArray.map(file => file);
        formik.setFieldValue('newPhotos', files);
        imageUpdate((prevImages) => [...prevImages, ...urls]);
        onClose();
    }

    return (
        <Stack>
            <Button backgroundColor={'#363449'} onClick={onOpen}>
                <UploadIcon color={'white'} />
                Subir fotos
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    <ModalHeader>Add a new image</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormikProvider value={formik}>
                            <FormControl
                                as={GridItem}
                                colSpan={6}
                                isInvalid={getIn(formik.errors, 'images')}
                                isRequired>
                                <Field
                                    as={FilePond}
                                    key="images"
                                    instantUpload={false}
                                    upload="false"
                                    maxFileSize={'3MB'}
                                    labelMaxFileSizeExceeded="Archivo demasiado grande"
                                    labelMaxFileSize="Tamaño máximo: 3MB"
                                    allowMultiple
                                    maxFiles={5}
                                    maxParallelUploads={5}
                                    imagePreviewMaxHeight={120}
                                    acceptedFileTypes={['image/*']}
                                    onupdatefiles={images => {
                                        setImageArray(images);
                                    }}
                                    labelIdle='Arrastre las imagenes o <span class="filepond--label-action">elijalas desde archivo</span>'
                                    labelFileLoading="Cargando"
                                    labelFileProcessing="Procesando"
                                    labelFileProcessingComplete="Procesado"
                                    labelFileProcessingError="Error"
                                    labelTapToCancel="Cancelar"
                                    labelTapToRetry="Reintentar"
                                    labelTapToUndo="Deshacer"
                                    labelButtonRemoveItem="Eliminar"
                                    labelFileLoadError={'Error al cargar el archivo'}
                                    onwarning={error => {
                                        toast({
                                            title: 'Limite de imagenes excedido',
                                            description: 'Solo se pueden subir 5 imagenes',
                                            status: 'info',
                                            duration: 5000,
                                            isClosable: true,
                                            position: 'top',
                                        });
                                    }}
                                    onerror={error => {
                                        formik.setFieldError('images', error.main);
                                    }}
                                />
                                {getIn(formik.errors, 'images') ? (
                                    <FormErrorMessage fontSize={'x-small'}>
                                        {getIn(formik.errors, 'images')}
                                    </FormErrorMessage>
                                ) : null}
                            </FormControl>
                        </FormikProvider>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={() => handleUploadPhtos(imageArray)}>Agregar</Button>
                        <Button onClick={onClose}>Cerrar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
}
const ScheduleEditingSection = ({ formik }) => {
    let openWorkTime = formik.values?.workTime || [];
    if (!Array.isArray(openWorkTime)) {
        openWorkTime = [openWorkTime]
    }

    return (
        <Box mt={20} paddingInline={5}>
            <Text mb={5}>Horarios comerciales</Text>
            <FormikProvider value={formik}>
                <FormControl
                    isInvalid={getIn(formik.errors, 'name')}
                    isRequired
                    as={GridItem}
                    colSpan={6}>
                    <Stack spacing={3}>
                        {openWorkTime?.map((item, index) => (
                            <div key={'itemDay-' + index}>
                                <OpenTimeForm
                                    index={index}
                                    formik={formik}
                                    day={item.dayNumber}
                                />
                            </div>
                        ))}
                    </Stack>
                </FormControl>
            </FormikProvider>
        </Box>
    )
}

type OpenTimeFormProps = {
    index: number;
    day: number;
    formik: any;
}

const OpenTimeForm = ({ index, formik, day }: OpenTimeFormProps) => {
    const { values, handleChange, setFieldValue } = formik;

    const opens = values.workTime[index]?.opens || false;
    const openTime = values.workTime[index]?.openTime || [];
    const closeTime = values.workTime[index]?.closeTime || [];

    const [openAllDay, setOpenAllDay] = useState(closeTime?.length === 2 ? false : true);

    const handleTimeChange = (field, value) => {
        setFieldValue(`workTime[${index}].${field}`, value);
    };

    return (
        <Stack mt={5} >
            <Box display={'flex'}>
                <Checkbox
                    mr={5}
                    isChecked={opens}
                    onChange={(e) => handleTimeChange('opens', e.target.checked)}
                    colorScheme={'brand.50'}
                    sx={{
                        '.chakra-checkbox__control[data-checked]': {
                            bg: 'brand.50',
                            borderColor: 'brand.50',
                        },
                        '.chakra-checkbox__control[hover]': {
                            borderColor: 'brand.50',
                        },
                    }} />
                <Text textColor={opens ? 'white' : '#777672'}>{daysArray[day - 1]}</Text>
            </Box>
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }} >
                <Box mt={5} mr={8} >
                    <FormControl
                        position="relative"
                        display={'flex'}
                        border={'1px solid var(--M3-sys-light-outline, #79747E);'}
                        borderRadius={'4px'}
                    >
                        <FormLabel
                            position="relative"
                            display={'flex'}
                            flexDir={'row'}
                            alignItems={'center'}
                            justifyItems={'center'}
                            mr={0}
                        >
                            <Input
                                as={Input}
                                type="time"
                                name={`workTime[${index}].openTime[0]`}
                                id={"worktime"}
                                autoComplete={"worktime"}
                                mt={3}
                                shadow="sm"
                                size="xs"
                                w="full"
                                rounded="md"
                                variant="outline"
                                borderColor="#79747E"
                                zIndex={1}
                                p={0}
                                marginInline={1}
                                mr={0}
                                ml={3}
                                value={opens && openTime[0]}
                                onChange={handleChange}
                                isDisabled={!opens}

                                width={'90px'}
                                border={"none"}
                                opacity={!opens ? '48%' : '100%'}
                                placeholder={'00:00'}
                                sx={{
                                    '&::-webkit-calendar-picker-indicator': {
                                        width: '1rem',
                                        height: '1rem',
                                        opacity: '0',
                                        zIndex: 4,
                                        position: 'relative',
                                        right: '-0.4rem',
                                    }
                                }}
                            />
                            <Icon
                                as={IoIosArrowDown}
                                position="relative"
                                right="0.6rem"
                                top="40%"
                                transform="translateY(-50%)"
                                pointerEvents="none"
                                color="white"
                                opacity={!opens ? '48%' : '100%'}
                                zIndex={3}
                            />
                        </FormLabel>
                        <FormLabel
                            style={{
                                position: "absolute",
                                top: "-0.8em",
                                left: "1em",
                                paddingInline: 4,
                                color: "gray.500",
                                backgroundColor: "#313149",
                                fontSize: "0.8em",
                                transition: "0.2s ease all",
                                fontWeight: "md",
                                zIndex: 2
                            }}
                            pointerEvents="none"
                            htmlFor="name"
                            _dark={{
                                color: 'gray.50',
                            }}>
                            <Text
                                textColor={!opens ? '#777672' : 'white'}
                            >
                                {'Desde'}
                            </Text>
                        </FormLabel>
                    </FormControl>
                </Box>
                <Box mt={5} mr={8} >
                    <FormControl
                        position="relative"
                        display={'flex'}
                        border={'1px solid var(--M3-sys-light-outline, #79747E);'}
                        borderRadius={'4px'}
                    >
                        <FormLabel
                            position="relative"
                            display={'flex'}
                            flexDir={'row'}
                            alignItems={'center'}
                            justifyItems={'center'}
                            mr={0}
                        >
                            <Input
                                as={Input}
                                type="time"
                                name={`workTime[${index}].closeTime[0]`}
                                id={"worktime"}
                                autoComplete={"worktime"}
                                mt={3}
                                shadow="sm"
                                size="xs"
                                w="full"
                                rounded="md"
                                variant="outline"
                                borderColor="#79747E"
                                zIndex={1}
                                p={0}
                                value={opens && closeTime[0]}
                                onChange={handleChange}
                                marginInline={1}
                                mr={0}
                                ml={3}
                                width={'90px'}
                                border={"none"}
                                opacity={!opens ? '48%' : '100%'}
                                placeholder={'00:00'}
                                isDisabled={!opens}
                                sx={{
                                    '&::-webkit-calendar-picker-indicator': {
                                        opacity: '0',
                                        zIndex: 4
                                    }
                                }}
                            />
                            <Icon
                                mt={2.5}
                                as={CarretArrowDownIcon}
                                position="relative"
                                right="0.6rem"
                                top="40%"
                                transform="translateY(-50%)"
                                pointerEvents="none"
                                color="white"
                                opacity={!opens ? '48%' : '100%'}
                                zIndex={3}
                            />
                        </FormLabel>
                        <FormLabel
                            style={{
                                position: "absolute",
                                top: "-0.8em",
                                left: "1em",
                                paddingInline: 4,
                                color: "gray.500",
                                backgroundColor: "#313149",
                                fontSize: "0.8em",
                                transition: "0.2s ease all",
                                fontWeight: "md",
                                zIndex: 2
                            }}
                            pointerEvents="none"
                            htmlFor="name"
                            _dark={{
                                color: 'gray.50',
                            }}>
                            <Text
                                textColor={!opens ? '#777672' : 'white'}
                            >
                                {'Hasta'}
                            </Text>
                        </FormLabel>
                    </FormControl>
                </Box>
                <Box mt={5}>
                    <Box display={'flex'} style={{ position: 'relative', bottom: !openAllDay ? -38 : 0 }}>
                        <Button background={'none'} isDisabled={!opens && (openTime?.length > 0 && closeTime?.length > 0)} onClick={() => setOpenAllDay(!openAllDay)}>
                            {(!openAllDay && opens) ? <IoMdRemove color={!opens ? '#777672' : 'white'} /> : <IoMdAdd color={!opens ? '#777672' : 'white'} />}
                        </Button>
                    </Box>
                </Box>
            </Box>
            {!openAllDay &&
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                }} >
                    <Box mt={5} mr={8} >
                        <FormControl
                            position="relative"
                            display={'flex'}
                            border={'1px solid var(--M3-sys-light-outline, #79747E);'}
                            borderRadius={'4px'}
                        >
                            <FormLabel
                                position="relative"
                                display={'flex'}
                                flexDir={'row'}
                                alignItems={'center'}
                                justifyItems={'center'}
                                mr={0}
                            >
                                <Input
                                    as={Input}
                                    type="time"
                                    name={`workTime[${index}].openTime[1]`}
                                    id={"worktime"}
                                    autoComplete={"worktime"}
                                    mt={3}
                                    shadow="sm"
                                    size="xs"
                                    w="full"
                                    rounded="md"
                                    variant="outline"
                                    borderColor="#79747E"
                                    zIndex={1}
                                    p={0}
                                    marginInline={1}
                                    mr={0}
                                    ml={3}
                                    value={openAllDay && openTime[1]}
                                    onChange={handleChange}
                                    isDisabled={!opens && openTime.length > 0}

                                    width={'90px'}
                                    border={"none"}
                                    opacity={!opens ? '48%' : '100%'}
                                    placeholder={'00:00'}
                                    sx={{
                                        '&::-webkit-calendar-picker-indicator': {
                                            width: '1rem',
                                            height: '1rem',
                                            opacity: '0',
                                            zIndex: 4,
                                            position: 'relative',
                                            right: '-0.4rem',
                                        }
                                    }}
                                />
                                <Icon
                                    as={IoIosArrowDown}
                                    position="relative"
                                    right="0.6rem"
                                    top="40%"
                                    transform="translateY(-50%)"
                                    pointerEvents="none"
                                    color="white"
                                    opacity={!opens ? '48%' : '100%'}
                                    zIndex={3}
                                />
                            </FormLabel>
                            <FormLabel
                                style={{
                                    position: "absolute",
                                    top: "-0.8em",
                                    left: "1em",
                                    paddingInline: 4,
                                    color: "gray.500",
                                    backgroundColor: "#313149",
                                    fontSize: "0.8em",
                                    transition: "0.2s ease all",
                                    fontWeight: "md",
                                    zIndex: 2
                                }}
                                pointerEvents="none"
                                htmlFor="name"
                                _dark={{
                                    color: 'gray.50',
                                }}>
                                <Text
                                    textColor={!opens ? '#777672' : 'white'}
                                >
                                    {'Desde'}
                                </Text>
                            </FormLabel>
                        </FormControl>
                    </Box>
                    <Box mt={5} mr={8} >
                        <FormControl
                            position="relative"
                            display={'flex'}
                            border={'1px solid var(--M3-sys-light-outline, #79747E);'}
                            borderRadius={'4px'}
                        >
                            <FormLabel
                                position="relative"
                                display={'flex'}
                                flexDir={'row'}
                                alignItems={'center'}
                                justifyItems={'center'}
                                mr={0}
                            >
                                <Input
                                    as={Input}
                                    type="time"
                                    name={`workTime[${index}].closeTime[1]`}
                                    id={"worktime"}
                                    autoComplete={"worktime"}
                                    mt={3}
                                    shadow="sm"
                                    size="xs"
                                    w="full"
                                    rounded="md"
                                    variant="outline"
                                    borderColor="#79747E"
                                    zIndex={1}
                                    p={0}
                                    value={openAllDay && closeTime[1]}
                                    onChange={handleChange}
                                    marginInline={1}
                                    mr={0}
                                    ml={3}
                                    width={'90px'}
                                    border={"none"}
                                    opacity={!opens ? '48%' : '100%'}
                                    placeholder={'00:00'}
                                    isDisabled={!opens && closeTime.length > 0}
                                    sx={{
                                        '&::-webkit-calendar-picker-indicator': {
                                            opacity: '0',
                                            zIndex: 4
                                        }
                                    }}
                                />
                                <Icon
                                    as={IoIosArrowDown}
                                    position="relative"
                                    right="0.6rem"
                                    top="40%"
                                    transform="translateY(-50%)"
                                    pointerEvents="none"
                                    color="white"
                                    opacity={!opens ? '48%' : '100%'}
                                    zIndex={3}
                                />
                            </FormLabel>
                            <FormLabel
                                style={{
                                    position: "absolute",
                                    top: "-0.8em",
                                    left: "1em",
                                    paddingInline: 4,
                                    color: "gray.500",
                                    backgroundColor: "#313149",
                                    fontSize: "0.8em",
                                    transition: "0.2s ease all",
                                    fontWeight: "md",
                                    zIndex: 2
                                }}
                                pointerEvents="none"
                                htmlFor="name"
                                _dark={{
                                    color: 'gray.50',
                                }}>
                                <Text
                                    textColor={!opens ? '#777672' : 'white'}
                                >
                                    {'Hasta'}
                                </Text>
                            </FormLabel>
                        </FormControl>
                    </Box>
                </Box>
            }
        </Stack>
    )
}