const InputData = {
    businessnameInput: [
        {
            name: 'name',
            text: 'Nombre de tu negocio',
        }
    ],
    dataInput: [
        {
            name: 'email',
            text: 'Email comercial',
        },
        {
            phone: [
                {
                    name: 'phoneRegion',
                    text: 'Prefijo',
                },
                {
                    name: 'phone',
                    text: 'Teléfono comercial',
                }
            ]
        },
        {
            name: 'web',
            text: 'Sitio web',
        },
        {
            name: 'instagram',
            text: 'Instagram',
        },
        {
            name: 'facebook',
            text: 'Facebook',
        },
        {
            name: 'tiktok',
            text: 'Tik Tok',
        },
        {
            name: 'googleBusiness',
            text: 'Google Business',
        },
    ]
}

export default InputData