import sc from 'states-cities-db';

export const COUNTRIES = sc.getCountries();

export const getCountryTelCode = country =>
  country !== '' &&
  country &&
  COUNTRIES.find(({ iso }) => iso === country).prefix;

export const getCountryCode = prefix =>
  prefix !== '' &&
  prefix &&
  COUNTRIES.find(({ prefix: p }) => p === prefix).iso;
  