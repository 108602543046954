/*eslint-disable*/
import { AddIcon, ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Portal,
  Avatar,
  MenuDivider,
  Skeleton,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverFooter,
  PopoverTrigger,
  PopoverBody,
  PopoverCloseButton,
  Input,
  Icon,
} from '@chakra-ui/react';
import IconBox from '../Icons/IconBox';
import { Separator } from '../Separator/Separator';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { BikletaIcon as logo } from '../../assets';
import { useStore } from '../../utils/useStore';
import SidebarItem from '../Admin/Sidebar/item';
// FUNCTIONS

interface SidebarProps {
  routes: any[];
  logoText: string;
  bikletaPointsState: {
    isLoading: boolean;
    bikletaPoints: any[] | null;
  };
  variant: string;
  onModalOpen: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  variant,
  onModalOpen,
  logoText,
  routes,
  bikletaPointsState,
}) => {
  // to check for active links and opened collapses
  let location = useLocation();

  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  const { activePoint, setActivePoint } = useStore();

  let variantChange = '0.2s linear';
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return location.pathname === routeName ? 'active' : '';
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = routes => {
    // Chakra Color Mode
    let activeBg = useColorModeValue('white', '#313149');
    let inactiveBg = useColorModeValue('white', 'gray.700');
    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue('gray.400', 'gray.400');
    let sidebarActiveShadow = '0px 7px 11px rgba(0, 0, 0, 0.04)';
    // Here are all the props that may change depending on sidebar's state.(Opaque or transparent)
    if (variant === 'opaque') {
      activeBg = 'transparent';
      inactiveBg = useColorModeValue('gray.100', 'gray.600');
      activeColor = useColorModeValue('gray.700', 'white');
      inactiveColor = useColorModeValue('gray.400', 'gray.400');
      sidebarActiveShadow = 'none';
    }

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category && prop.category !== 'section') {
        var st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <Skeleton isLoaded={!!activePoint} key={'section-' + key}>
            <Text
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py="10px">
              {document.documentElement.dir === 'rtl'
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </Skeleton>
        );
      }
      return <SidebarItem route={prop} key={key} activePoint={activePoint} />;
    });
  };

  var links = <div>{createLinks(routes)}</div>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarRadius = '0px';
  let sidebarMargins = '0px';
  if (variant === 'opaque') {
    sidebarRadius = '0px';
    sidebarMargins = '16px';
  }

  const initRef = React.useRef();

  const [filteredBikletaPoint, setFilteredBikletaPoint] = useState(
    bikletaPointsState.bikletaPoints
  );

  useEffect(() => {
    setFilteredBikletaPoint(bikletaPointsState.bikletaPoints);
  }, [bikletaPointsState.bikletaPoints]);


  const filterMyBPs = event => {
    const filteredArray = bikletaPointsState.bikletaPoints.filter(
      item =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase()) &&
        item
    );

    setFilteredBikletaPoint(filteredArray);
  };

  var brand = (
    <Stack pt={'25px'} mb="12px" spacing={5}>
      <Skeleton isLoaded={!bikletaPointsState.isLoading}>
        {bikletaPointsState.bikletaPoints &&
          bikletaPointsState.bikletaPoints.length > 0 ? (
          <Popover
            closeOnBlur={true}
            placement="bottom-start"
            initialFocusRef={initRef}
            isLazy>
            {({ isOpen, onClose }) => (
              <>
                <Menu>
                  <PopoverTrigger>
                    <MenuButton
                      as={Button}
                      size="lg"
                      rounded="none"
                      minW="100%"
                      maxW="100%"
                      fontSize={'sm'}
                      fontWeight="bold"
                      rightIcon={<ChevronDownIcon />}
                      leftIcon={<Avatar size={'xs'} src={logo} />}
                      ps={{
                        sm: '10px',
                        xl: '16px',
                      }}
                      bgColor="#1F1F2E">
                      <Text isTruncated>{activePoint?.name}</Text>
                    </MenuButton>
                  </PopoverTrigger>
                </Menu>
                <Portal>
                  <PopoverContent maxW={{ sm: '90vw', md: '20vw' }}>
                    <PopoverHeader>
                      <Input
                        ref={initRef}
                        type={'text'}
                        placeholder="Buscar Bikleta Point..."
                        size={'sm'}
                        rounded={'md'}
                        w={'90%'}
                        onChange={filterMyBPs}
                      />
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody
                      maxH={'40vh'}
                      overflowY="auto"
                      p={0}
                      bgColor="blackAlpha.400"
                      sx={{
                        '&::-webkit-scrollbar': {
                          width: '7px',
                          borderRadius: '10px',
                          backgroundColor: `rgba(0, 0, 0, 0.1)`,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: `rgba(0, 0, 0, 0.4)`,
                          borderRadius: '10px',
                        },
                      }}>
                      {filteredBikletaPoint &&
                        filteredBikletaPoint.map((point, key) => (
                          <Button
                            w={'100%'}
                            maxW={'100%'}
                            variant="ghost"
                            rounded={'none'}
                            justifyContent={'flex-start'}
                            onClick={() => {
                              setActivePoint(point);
                              onClose();
                            }}
                            key={'MenuItem-' + key}
                            isTruncated>
                            <Image
                              boxSize="1.5rem"
                              borderRadius="full"
                              src={logo}
                              alt="Icon BP"
                              mr={3}
                            />
                            <Text isTruncated fontWeight={'normal'}>
                              {point.name}
                            </Text>
                          </Button>
                        ))}
                    </PopoverBody>
                    <PopoverFooter p={0}>
                      <Button
                        leftIcon={<AddIcon mr={3} />}
                        w="100%"
                        fontSize={'sm'}
                        fontWeight="bold"
                        variant="ghost"
                        rounded={'none'}
                        justifyContent={'flex-start'}
                        onClick={onModalOpen}>
                        Registrar Bikleta Point
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        ) : (
          <Button
            leftIcon={<AddIcon mr={3} />}
            w="100%"
            marginX={'auto'}
            colorScheme={'yellow'}
            rounded={'md'}
            justifyContent={'flex-start'}
            size="sm"
            variant={'outline'}
            onClick={onModalOpen}>
            Registrar Bikleta Point
          </Button>
        )}
      </Skeleton>
    </Stack>
  );

  // SIDEBAR
  return (
    <Box ref={mainPanel} bgColor={'#27273A'}>
      <Box
        display={{ sm: 'none', xl: 'block' }}
        position="fixed"
        bgColor={'#27273A'}
        h="100%">
        <Box
          mt="50px"
          transition={variantChange}
          w="290px"
          maxW="290px"
          pr={4}
          borderRadius={sidebarRadius}>
          <Box px={6}>{brand}</Box>
          <Stack px={6} direction="column" spacing={4}>
            {links}
          </Stack>
        </Box>
        <Box
          pos={'absolute'}
          bottom="0"
          w="100%"
          textAlign={'center'}
          py="2"
          bgColor={'#1F1F2E'}>
          <Text color={'#4E4E73'}>VERSIÓN 1.0.0</Text>
        </Box>
      </Box>
    </Box>
  );
};

// FUNCTIONS

export const SidebarResponsive = ({
  variant,
  onModalOpen,
  logoText,
  routes,
  bikletaPointsState,
  secondary,
}) => {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return location.pathname === routeName ? 'active' : '';
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = routes => {
    // Chakra Color Mode
    const activeBg = useColorModeValue('white', 'gray.700');
    const inactiveBg = useColorModeValue('white', 'gray.700');
    const activeColor = useColorModeValue('gray.700', 'white');
    const inactiveColor = useColorModeValue('gray.400', 'gray.400');

    return routes.map((prop, key) => {
      if (prop.redirect || prop.hidden) {
        return null;
      }
      if (prop.category && prop.category !== 'section') {
        var st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <div key={'sectionResponsive-' + key}>
            <Text
              color={activeColor}
              fontWeight="bold"
              mb={{
                xl: '12px',
              }}
              mx="auto"
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py="12px">
              {document.documentElement.dir === 'rtl'
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </div>
        );
      }
      if (prop.category === 'section') {
        var isActive = '/' + location.pathname.split('/')[2] === prop.path;
        return (
          <Menu key={'sectionResponsive-' + key}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronRightIcon />}
              boxSize="initial"
              boxShadow={isActive ? '0px 7px 11px rgba(0, 0, 0, 0.04)' : 'none'}
              justifyContent="flex-start"
              alignItems="center"
              bg={isActive ? activeBg : inactiveBg}
              transition={'0.2s linear'}
              mb={{
                xl: '12px',
              }}
              mx={{
                xl: 'auto',
              }}
              py="12px"
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              borderRadius="15px"
              w="100%"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: isActive
                  ? '0px 7px 11px rgba(0, 0, 0, 0.04)'
                  : 'none',
              }}>
              <Flex>
                {isActive ? (
                  typeof prop.icon === 'string' ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <IconBox
                      bg="yellow.500"
                      color="white"
                      h="30px"
                      w="30px"
                      me="12px"
                      transition={'0.2s linear'}>
                      {prop.icon}
                    </IconBox>
                  )
                ) : typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color="yellow.300"
                    h="30px"
                    w="30px"
                    me="12px">
                    {prop.icon}
                  </IconBox>
                )}
                <Text
                  color={inactiveColor}
                  my="auto"
                  fontSize="sm"
                  fontWeight={'bold'}>
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              {prop.views.map((item, key) => {
                return (
                  <MenuItem
                    key={'MenuItem-' + key}
                    icon={item.icon}
                    as={NavLink}
                    to={item.layout + prop.path + item.path}>
                    <Text color={activeColor} fontSize="sm">
                      {document.documentElement.dir === 'rtl'
                        ? item.rtlName
                        : item.name}
                    </Text>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        );
      }

      return (
        <NavLink to={prop.layout + prop.path} key={'sectionResponsive-' + key}>
          {activeRoute(prop.layout + prop.path) === 'active' ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{
                xl: '12px',
              }}
              mx={{
                xl: 'auto',
              }}
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py="12px"
              borderRadius="15px"
              w="100%"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}>
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg="yellow.300"
                    color="white"
                    h="30px"
                    w="30px"
                    me="12px">
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              mb={{
                xl: '12px',
              }}
              mx={{
                xl: 'auto',
              }}
              py="12px"
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              borderRadius="15px"
              w="100%"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}>
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color="yellow.300"
                    h="30px"
                    w="30px"
                    me="12px">
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my="auto" fontSize="sm">
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let hamburgerColor = useColorModeValue('gray.500', 'gray.200');
  if (secondary === true) {
    hamburgerColor = 'white';
  }

  /* const [filteredBikletaPoint, setFilteredBikletaPoint] = useState(
    bikletaPointsState.bikletaPoints
  );

  useEffect(() => {
    setFilteredBikletaPoint(bikletaPointsState.bikletaPoints);
  }, [bikletaPointsState.bikletaPoints]);

  const filterMyBPs = event => {
    const filteredArray = bikletaPointsState.bikletaPoints.filter(
      item =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase()) &&
        item
    );

    setFilteredBikletaPoint(filteredArray);
  }; */

  var brand = (
    <Box pt={'35px'} mb="8px">
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        mb="30px"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        fontSize="11px">
        <Image w="32px" h="32px" me="10px" src={logo} alt="Logo image" />
        <Text fontSize="sm" mt="3px">
          {logoText}
        </Text>
      </Link>
      <Separator></Separator>
    </Box>
  );

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // Color variables
  return (
    <Flex
      display={{ sm: 'flex', xl: 'none' }}
      ref={mainPanel}
      alignItems="center">
      <HamburgerIcon
        color={hamburgerColor}
        w="18px"
        h="18px"
        ref={btnRef}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent
          w="250px"
          maxW="250px"
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius="16px"
          bgColor={'#27273A'}>
          <DrawerCloseButton
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="250px" px="1rem">
            <Box maxW="100%" h="100vh">
              <Box>{brand}</Box>
              <Stack direction="column" mb="40px">
                <Box>{links}</Box>
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
// PROPS

SidebarResponsive.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
