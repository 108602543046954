import { useAsync } from '../../../utils/useAsync';
import { ConfigControllerProps } from './interfaces';
import { usePlacesSearchService } from '../../../services/placesService/placesService';
import { useBikletaPointsService } from '../../../services/bikletaPoints/bikletaPointsService';
import { useState } from 'react';
import { useStore } from '../../../utils/useStore';

export const useCommerceProfileViewController = (): ConfigControllerProps => {
  const service = usePlacesSearchService();
  const bpService = useBikletaPointsService();
  const [isLoading, setIsLoading] = useState(false);
  const { updateBikletaPoint } = useStore();
  const [resultsData, , loadingResults, executeSearch, ,] = useAsync(
    service.fetchResults,
    null
  );

  const getInfoFromPhoneNumber = number => {
    const sections = number.split(' ');
    if (sections.length === 1) {
      return {
        phoneNumber: sections[0],
      };
    }
    return {
      region: sections[0],
      phoneNumber: sections.slice(1, sections.length).join(''),
    };
  };

  const submitEditedData = async (id: string, data: any) => {
    try {
      let newPhotos = data.newPhotos;
      let images = data.images;
      
      delete data.newPhotos;
      delete data.images;

      setIsLoading(true);

      const formData = new FormData();

      newPhotos.forEach((file) => {
        formData.append('newPhotos', file.file);
      });

      const filteredImages = images.filter(url => !url.includes('blob'));
      //TODO: Fix this validation 
      
      filteredImages.forEach((url) => {
        formData.append('images', url);
      });

      
      await bpService.editPointData(id, data);

      const bkpUpdated = await bpService.updatePhotos(formData, id);


      updateBikletaPoint({
        ...bkpUpdated,
        name: data.name,
        type: data.type,
        email: data.email,
        phone: data.phone,
        phoneRegion: data.phoneRegion,
        address: data.address,
        point: data.point,
        web: data.web,
      });
    } catch (error) {
      console.error('Error edit: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    setSearchQuery: executeSearch,
    loadingData: loadingResults,
    placesSearchResults: resultsData?.results,
    getInfoFromPhoneNumber,
    submitEditedData,
    isSubmitting: isLoading,
  };
};
