import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@chakra-ui/react';
import Flag from 'react-world-flags';
import { AsYouType } from 'libphonenumber-js';
import { getCountryTelCode } from '../utils/countries';
import { RiEarthFill } from 'react-icons/ri';

interface PhoneNumberInputProps {
  country: string;
  inputProps: InputProps;
  onChange: Function;
  options: any[];
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  country,
  inputProps,
  onChange,
  options,
  ...rest
}) => {
  let [number, setNumber] = useState(inputProps.value || '');
  let [selectedCountry, setSelectedCountry] = useState(country || '');
  let [countryCode, setCountryCode] = useState(
     getCountryTelCode(country) || ''
  );

  useEffect(() => {
    setSelectedCountry(country);
    setCountryCode(getCountryTelCode(country));
  }, [country]);

  const onCountryChange = e => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    let parsedNumber = new AsYouType().input(`${code}${number}`);

    setCountryCode(code);
    setSelectedCountry(value);
    onChange(parsedNumber);
  };

  const onPhoneNumberChange = e => {
    let value = e.target.value;
    let parsedNumber = new AsYouType().input(`${countryCode}${value}`);

    setNumber(value);
    onChange(parsedNumber);
  };
  return (
    <InputGroup size={inputProps.size} {...rest}>
      <InputLeftElement width="4rem" mr={3}>
        <Select
          top="25%"
          right="-1"
          zIndex={1}
          bottom={0}
          opacity={0}
          color={!selectedCountry ? 'white' : 'transparent'}
          height="100%"
          position="absolute"
          value={selectedCountry}
          onChange={onCountryChange}>
          <option value="" />
          {options.map((option, index: number) => (
            <option value={option.value} key={'option-' + index}>
              {option.label}
            </option>
          ))}
        </Select>
        <Flex pl={0.5} width="50%" alignItems="center">
          {selectedCountry ? (
            <Box flex={1}>
              <Flag height="1rem" code={selectedCountry || ''} />
            </Box>
          ) : (
            <Icon as={RiEarthFill} />
          )}
        </Flex>
      </InputLeftElement>
      <Input
        pl="4rem"
        type="tel"
        value={number}
        pattern="[0-9]"
        onChange={onPhoneNumberChange}
        {...inputProps}
      />
    </InputGroup>
  );
};

export default PhoneNumberInput;
