import {
  FaCheckCircle,
  FaCreditCard,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaParking,
  FaShippingFast,
  FaWifi,
  FaAddressCard,
  FaWallet,
  FaRestroom,
} from 'react-icons/fa';
import {
  MdEvStation,
  MdPets,
  MdAssignment,
  MdLocalOffer,
} from 'react-icons/md';
import { HiQrcode } from 'react-icons/hi';
import { AiFillCreditCard } from 'react-icons/ai';
import {
  RiQrCodeLine,
  RiSignalTowerFill,
  RiToolsFill,
  RiMotorbikeFill,
  RiShoppingBagFill,
} from 'react-icons/ri';
import { GoGear } from 'react-icons/go';
import { IoIosWater, IoMdQrScanner } from 'react-icons/io';
import { AiFillShop } from 'react-icons/ai';
import { ApexOptions } from 'apexcharts';
import { slidesImages } from '../assets';
import { BenefitsType } from '../services/bikletaPoints/dto';

export const centerDef = {
  lat: parseFloat('-31.417'),
  lng: parseFloat('-64.183'),
};

export const colourOptions = [
  { value: 'blue', label: 'Azul', color: '#0052CC' },
  { value: 'purple', label: 'Violeta', color: '#5243AA' },
  { value: 'red', label: 'Rojo', color: '#FF5630' },
  { value: 'orange', label: 'Naranja', color: '#FF8B00' },
  { value: 'yellow', label: 'Amarillo', color: '#FFC400' },
  { value: 'green', label: 'Verde', color: '#36B37E' },
];

export const brandsOptions = [
  { value: 'Venzo', label: 'Venzo' },
  { value: 'Tomaselli', label: 'Tomaselli' },
  { value: 'Bikleta', label: 'Bikleta' },
];

export const storeLinks = {
  android: 'https://play.google.com/store/apps/details?id=com.bikletaapp',
  apple: 'https://apps.apple.com/ar/app/bikleta/id1635371316?l=en',
};

export const contactForms = [
  {
    text: 'Información general:',
    secondaryText: 'bikleta@gmailcom',
    url: 'mailto:bikleta@gmail.com',
    icon: FaEnvelope,
  },
  {
    text: 'Instagram:',
    secondaryText: 'bikleta_app',
    url: 'https://www.instagram.com/bikleta_app/',
    icon: FaInstagram,
  },
  {
    text: 'Facebook:',
    secondaryText: 'BikletaApp',
    url: 'https://www.facebook.com/BikletaApp',
    icon: FaFacebook,
  },
];

export const testCommerces = [
  {
    name: 'Probike',
    _id: '62fbddaeae9049bb8a10595a',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: '12 De Octubre 623' },
    point: { lat: -24.8525422, lng: -65.460335 },
    type: 'foodStore',
    owner: { name: 'Bikleta', phone: '3875139971' },
    images: null,
  },
  {
    name: 'Chalabe Bicicletas',
    _id: '62fbddadae9049bb8a105948',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Urquiza 639' },
    point: { lat: -24.7924147, lng: -65.4117777 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3874214361' },
    images: null,
  },
  {
    name: 'El Rayo',
    _id: '62fbddaeae9049bb8a10595c',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Tucuman 410' },
    point: { lat: -24.8000722, lng: -65.4094056 },
    type: 'clothesShop',
    owner: { name: 'Bikleta', phone: '3874232564' },
    images: null,
  },
  {
    name: 'Manresa Bicileteria',
    _id: '62fbddaeae9049bb8a105985',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Corrientes 905' },
    point: { lat: -24.8009812, lng: -65.4165318 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3874233359' },
    images: null,
  },
  {
    name: 'La Casa Del Ciclista',
    _id: '62fbddafae9049bb8a1059a0',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'San Martin 1291' },
    point: { lat: -24.1868156, lng: -65.3107899 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3884230892' },
    images: null,
  },
  {
    name: 'La Bicicleta',
    _id: '62fbddafae9049bb8a1059a6',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Gorriti 471' },
    point: { lat: -24.191552, lng: -65.296194 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3884229672' },
    images: null,
  },
  {
    name: 'Casanova',
    _id: '62fbddafae9049bb8a1059c3',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Alem 854 Sur' },
    point: { lat: -26.8399467, lng: -65.221081 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814244794' },
    images: null,
  },
  {
    name: 'Osvaldo Postigo',
    _id: '62fbddafae9049bb8a1059b7',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Gral. Lavalle 62' },
    point: { lat: -24.1881658, lng: -65.3028002 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3884241771' },
    images: null,
  },
  {
    name: 'Fenix Bike',
    _id: '62fbddafae9049bb8a1059b9',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Reyes Catolicos 2085' },
    point: { lat: -24.7587124, lng: -65.3987265 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3875190938' },
    images: null,
  },
  {
    name: 'Bikestore',
    _id: '62fbddafae9049bb8a1059bb',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av.alem 187' },
    point: { lat: -26.8306489, lng: -65.219458 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3813442461' },
    images: null,
  },
  {
    name: 'Farol Bike',
    _id: '62fbddafae9049bb8a1059d4',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Mate De Luna 2850' },
    point: { lat: -26.8234296, lng: -65.2406627 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3816031667' },
    images: null,
  },
  {
    name: 'Bikeshop',
    _id: '62fbddafae9049bb8a1059c7',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Arturo Illia 57' },
    point: { lat: -27.8081731, lng: -64.2481693 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3855189461' },
    images: null,
  },
  {
    name: 'Bicicleteria Russo',
    _id: '62fbddafae9049bb8a1059d2',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Libertad 2590' },
    point: { lat: -27.8017819, lng: -64.2830294 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3854395640' },
    images: null,
  },
  {
    name: 'Bicicleteria Roca',
    _id: '62fbddafae9049bb8a1059c5',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Pres Kirchner 2165' },
    point: { lat: -26.8379345, lng: -65.2332996 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814507990' },
    images: null,
  },
  {
    name: 'Starlap',
    _id: '62fbddafae9049bb8a1059ce',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av.camino Del Peru 624' },
    point: { lat: -26.8089791, lng: -65.2631905 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3815995966' },
    images: null,
  },
  {
    name: 'Bicicleteria Rodanor',
    _id: '62fbddafae9049bb8a1059d0',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Juan B Justo 1082' },
    point: { lat: -26.8175789, lng: -65.1937461 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814975732' },
    images: null,
  },
  {
    name: 'Bicicletas Tomas',
    _id: '62fbddafae9049bb8a1059f9',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Flor De Cuyo Dario 197' },
    point: { lat: -32.9558, lng: -68.8543698 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2616925167' },
    images: null,
  },
  {
    name: 'Yuhmak Bikes',
    _id: '62fbddb0ae9049bb8a1059fe',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Avellaneda 870' },
    point: { lat: -26.8205925, lng: -65.1944958 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814214656' },
    images: null,
  },
  {
    name: 'Xtreme Bike',
    _id: '62fbddafae9049bb8a1059fb',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Pcia De La Rioja 215 Sur' },
    point: { lat: -26.8324443, lng: -65.2130847 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814219709' },
    images: null,
  },
  {
    name: 'Salomon',
    _id: '62fbddafae9049bb8a1059f4',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Benjamin Araoz 119' },
    point: { lat: -26.8330535, lng: -65.195194 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814975370' },
    images: null,
  },
  {
    name: 'Gallo Bike',
    _id: '62fbddb0ae9049bb8a105a13',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Santiago Araujo 569' },
    point: { lat: -32.900495, lng: -68.7908343 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2615988432' },
    images: null,
  },
  {
    name: 'Bicicleteria Fenix',
    _id: '62fbddb0ae9049bb8a105a1d',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Soldado Desconocido' },
    point: { lat: -32.9042051, lng: -68.7966082 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2616102493' },
    images: null,
  },
  {
    name: 'Leo Bike',
    _id: '62fbddb0ae9049bb8a105a25',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Videla Correa 388' },
    point: { lat: -32.8707825, lng: -68.839412 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2617105350' },
    images: null,
  },
  {
    name: 'Bora Bikes',
    _id: '62fbddb0ae9049bb8a105a21',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Fray Mamerto Esquiu 901' },
    point: { lat: -31.4076406, lng: -64.1737261 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3515333763' },
    images: null,
  },
  {
    name: 'Ambrosi',
    _id: '62fbddb0ae9049bb8a105a15',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Sarmiento 1152' },
    point: { lat: -32.9399298, lng: -68.83275189999999 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2614520253' },
    images: null,
  },
  {
    name: 'Bici Mania',
    _id: '62fbddb0ae9049bb8a105a18',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Brasil 341' },
    point: { lat: -32.9046858, lng: -68.83927229999999 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2614243282' },
    images: null,
  },
  {
    name: 'Pottenza',
    _id: '62fbddb0ae9049bb8a105a27',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'O Brien 1169' },
    point: { lat: -32.8884538, lng: -68.8167528 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2613980411' },
    images: null,
  },
  {
    name: 'Palito Macias',
    _id: '62fbddb0ae9049bb8a105a1f',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Gral. Arenales 2801' },
    point: { lat: -24.7693074, lng: -65.4379235 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3874364370' },
    images: null,
  },
  {
    name: 'Gonzales Cycles',
    _id: '62fbddb0ae9049bb8a105a33',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av Duarte Quiroz 3065' },
    point: { lat: -31.4072181, lng: -64.2236825 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3514880337' },
    images: null,
  },
  {
    name: 'Bicicleteria Del Sur',
    _id: '62fbddb0ae9049bb8a105a2a',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Cacheuta 3332' },
    point: { lat: -31.4466444, lng: -64.21190279999999 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3518810470' },
    images: null,
  },
  {
    name: 'Mc Bike',
    _id: '62fbddb0ae9049bb8a105a23',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. Republica De Siria 1851' },
    point: { lat: -26.8047664, lng: -65.2041088 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3814552313' },
    images: null,
  },
  {
    name: 'Bicicleteria Calvo',
    _id: '62fbddafae9049bb8a1059f7',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av. San Martin 2341' },
    point: { lat: -32.8426846, lng: -68.8336023 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2615916585' },
    images: null,
  },
  {
    name: 'Bike Center Tucuman',
    _id: '62fbddb0ae9049bb8a105a31',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Marcos Paz 746' },
    point: { lat: -26.820891, lng: -65.2061027 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3812331687' },
    images: null,
  },
  {
    name: 'Dlracing',
    _id: '62fbddb0ae9049bb8a105a1a',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Carril Rodriguez Peña 2121' },
    point: { lat: -32.9278509, lng: -68.8196876 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '2615441449' },
    images: null,
  },
  {
    name: 'Dor Sport House',
    _id: '62fbddb1ae9049bb8a105a83',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Marcelo T Alvear 596' },
    point: { lat: -24.7821523, lng: -65.4185261 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3874474896' },
    images: null,
  },
  {
    name: 'Uni Bikes',
    _id: '62fbddb0ae9049bb8a105a5d',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Blvd San Juan 593' },
    point: { lat: -31.4189715, lng: -64.194009 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3513013177' },
    images: null,
  },
  {
    name: 'Bicicleteria Rodados',
    _id: '62fbddb0ae9049bb8a105a64',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Av Fuerza Aerea 2816' },
    point: { lat: -31.4309819, lng: -64.2259547 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '' },
    images: null,
  },
];

export const testCommercesShort = [
  {
    name: 'Probike Probike Probike Probike',
    _id: '62fbddaeae9049bb8a10595a',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: '12 De Octubre 623' },
    point: { lat: -24.8525422, lng: -65.460335 },
    type: 'foodStore',
    owner: { name: 'Bikleta', phone: '3875139971' },
    images: [
      'https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=876&q=80',
    ],
  },
  {
    name: 'Chalabe Bicicletas',
    _id: '62fbddadae9049bb8a105948',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Urquiza 639' },
    point: { lat: -24.7924147, lng: -65.4117777 },
    type: 'BikeWorkshop',
    owner: { name: 'Bikleta', phone: '3874214361' },
    images: [
      'https://images.unsplash.com/photo-1605271864611-58dd08d10547?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    ],
  },
  {
    name: 'Testing Shop',
    _id: '62fbddaeae9049bb8a10595c',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Tucuman 410' },
    point: { lat: -24.8000722, lng: -65.4094056 },
    type: 'clothesShop',
    owner: { name: 'Bikleta', phone: '3874232564' },
    images: null,
  },
  {
    name: 'El Rayo',
    _id: '62fbddaeae9049bb8a10595c',
    percentage: 0,
    qr: '',
    meta: {},
    address: { addressLine: 'Tucuman 410' },
    point: { lat: -24.8000722, lng: -65.4094056 },
    type: 'clothesShop',
    owner: { name: 'Bikleta', phone: '3874232564' },
    images: [
      'https://images.unsplash.com/photo-1607109181641-74f8e7f4eb11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
    ],
  },
];

export const testStolenBikes = [
  {
    name: 'test bike',
    model: 'test Model',
    brand: 'test brand',
    bikeBoolean: true,
    bikeType: 'child',
    frameSize: 'L',
    serialNumber: 'AB5656961956',
    owner: {
      alias: 'adminerminer',
      email: 'admin@bikleta.com',
      firstname: 'Admin',
      phone: '3513390283',
    },
    status: {
      name: 'robada',
      datetime: '2022-06-03T19:19:02.933Z',
      meta: {
        location: {
          lat: -31.39268092153202,
          lng: -64.15973054561456,
        },
      },
    },
  },
  {
    name: 'test bike',
    model: 'test Model',
    brand: 'test brand',
    bikeBoolean: true,
    bikeType: 'child',
    frameSize: 'L',
    serialNumber: 'AB5656961956',
    owner: {
      alias: 'adminerminer',
      email: 'admin@bikleta.com',
      firstname: 'Admin',
      phone: '3513390283',
    },
    status: {
      name: 'robada',
      datetime: '2022-06-03T19:19:02.933Z',
      meta: {
        location: {
          lat: -31.39268092143202,
          lng: -64.15973054561456,
        },
      },
    },
  },
  {
    name: 'test bike',
    model: 'test Model',
    brand: 'test brand',
    bikeBoolean: true,
    bikeType: 'child',
    frameSize: 'L',
    serialNumber: 'AB5656961956',
    owner: {
      alias: 'adminerminer',
      email: 'admin@bikleta.com',
      firstname: 'Admin',
      phone: '3513390283',
    },
    status: {
      name: 'robada',
      datetime: '2022-06-03T19:19:02.933Z',
      meta: {
        location: {
          lat: -31.37262092143202,
          lng: -64.19973054561456,
        },
      },
    },
  },
  {
    name: 'test bike',
    model: 'test Model',
    brand: 'test brand',
    bikeBoolean: true,
    bikeType: 'child',
    frameSize: 'L',
    serialNumber: 'AB5656961956',
    owner: {
      alias: 'adminerminer',
      email: 'admin@bikleta.com',
      firstname: 'Admin',
      phone: '3513390283',
    },
    status: {
      name: 'robada',
      datetime: '2022-06-03T19:19:02.933Z',
      meta: {
        location: {
          lat: -31.39248292143202,
          lng: -64.15943354561456,
        },
      },
    },
  },
  {
    name: 'test bike',
    model: 'test Model',
    brand: 'test brand',
    bikeBoolean: true,
    bikeType: 'child',
    frameSize: 'L',
    serialNumber: 'AB5656961956',
    owner: {
      alias: 'adminerminer',
      email: 'admin@bikleta.com',
      firstname: 'Admin',
      phone: '3513390283',
    },
    status: {
      name: 'robada',
      datetime: '2022-06-03T19:19:02.933Z',
      meta: {
        location: {
          lat: -31.39868092143202,
          lng: -64.15373054561456,
        },
      },
    },
  },
];

export const PointTypes = [
  {
    value: 'BikeWorkshop',
    label: 'Taller de Bicicletas',
  },
  {
    value: 'clothesShop',
    label: 'Tienda Deportiva',
  },
  {
    value: 'tourismPoint',
    label: 'Centro Turistico',
  },
  {
    value: 'BikeStore',
    label: 'Tienda de Bicicletas',
  },
  {
    value: 'coffieBar',
    label: 'Cafetería',
  },
  {
    value: 'bar',
    label: 'Bar',
  },
  {
    value: 'restaurant',
    label: 'Restaurante',
  },
];

export const sellsData = [
  {
    logo: FaCreditCard,
    title: 'Ventas acreditadas',
    date: '22',
    color: 'orange.300',
  },
  {
    logo: FaShippingFast,
    title: 'Ordenes Enviadas',
    date: '36',
    color: 'purple.400',
  },
  {
    logo: FaCheckCircle,
    title: 'Ventas Concretadas',
    date: '112',
    color: 'green.400',
  },
];

export const barChartData = [
  {
    name: 'Sales',
    data: [330, 250, 110, 300, 490, 350, 270, 130, 425],
  },
];

export const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      backgroundColor: 'red',
      fontSize: '12px',
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        backgroundColor: 'red',
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
    theme: 'dark',
  },
  xaxis: {
    categories: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    show: false,
    labels: {
      show: false,
      style: {
        colors: '#fff',
        fontSize: '12px',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: '#fff',
    labels: {
      show: true,
      style: {
        colors: '#fff',
        fontSize: '14px',
      },
    },
  },
  grid: {
    show: false,
  },
  fill: {
    colors: '#fff',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: '12px',
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

export const lineChartData = [
  {
    name: 'Vistas del Negocio',
    data: [200, 300, 700],
  },
];

export const lineChartOptions: ApexOptions = {
  chart: {
    type: 'line',
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: 'dark',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    type: 'category',
    categories: ['Sep', 'Oct', 'Nov'],
    labels: {
      style: {
        colors: '#c8cfca',
        fontSize: '12px',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#c8cfca',
        fontSize: '12px',
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: 'solid',
    colors: ['#f7d42d', '#2D3748'],
  },
  colors: ['#f7d42d', '#ffffff', '#5288f2'],
};

export const howToItems = [
  {
    image: require('../assets/howItWorks/how-1.webp'),
    title: 'Registrate en la App',
    text: 'Descarga la app desde Google Play o App Store',
  },
  {
    image: require('../assets/howItWorks/how-2.webp'),
    title: 'Se parte de la comunidad',
    text: 'Sigue los pasos con tu smartphone para registrarte en nuestra comunidad',
  },
  {
    image: require('../assets/howItWorks/how-3.webp'),
    title: 'Disfruta de los beneficios',
    text: 'Monitorea y comparte tus recorridos, reporta robo o extravío y descrubre los Bliketa Points',
  },
];

export const daysArray = [
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sabado',
  'Domingo'
];

export const RequestStates = [
  {
    state: 'Recibido',
    color: 'green',
  },
  {
    state: 'Cancelado',
    color: 'red',
  },
  {
    state: 'En camino',
    color: 'yellow',
  },
  {
    state: 'Solicitado',
    color: 'blue',
  },
];

export const RequestItemType = [
  {
    itemName: 'Beacon',
    icon: RiSignalTowerFill,
  },
  {
    itemName: 'QR Bikleta',
    icon: RiQrCodeLine,
  },
  {
    itemName: 'Carta QR',
    icon: MdAssignment,
  },
];

export const BenefitsItemType = [
  {
    itemName: 'discount',
    icon: MdLocalOffer,
  },
];

export const BenefitsStates = [
  {
    state: 'active',
    color: 'green',
  },
  {
    state: 'inactive',
    color: 'red',
  },
  {
    state: 'paused',
    color: 'yellow',
  },
];

export const ServicesItemTypes = [
  {
    type: 'wifiFree',
    itemName: 'Wi-Fi gratuito',
    icon: FaWifi,
  },
  {
    type: 'chargeStation',
    itemName: 'Estación de carga',
    icon: MdEvStation,
  },
  {
    type: 'hidratationPoint',
    itemName: 'Punto de hidratación',
    icon: IoIosWater,
  },
  {
    type: 'parkingSpot',
    itemName: 'Estacionamiento de Bikletas',
    icon: FaParking,
  },
  {
    type: 'petFriendly',
    itemName: 'Pet friendly',
    icon: MdPets,
  },
  {
    type: 'QRPayment',
    itemName: 'Pago con QR',
    icon: HiQrcode,
  },
  {
    type: 'eWallet',
    itemName: 'Billetera electronica',
    icon: FaWallet,
  },
  {
    type: 'debitCard',
    itemName: 'Tarjeta de debito',
    icon: AiFillCreditCard,
  },
  {
    type: 'creditCard',
    itemName: 'Tarjeta de credito',
    icon: FaAddressCard,
  },
  {
    type: 'delivery',
    itemName: 'Entregas a domicilio',
    icon: RiMotorbikeFill,
  },
  {
    type: 'repairs',
    itemName: 'Reparaciones',
    icon: RiToolsFill,
  },
  {
    type: 'spareParts',
    itemName: 'Repuestos',
    icon: GoGear,
  },
  {
    type: 'unisexToilets',
    itemName: 'Sanitarios unisex',
    icon: FaRestroom,
  },
  {
    type: 'shop',
    itemName: 'Compras en tiendas',
    icon: RiShoppingBagFill,
  },
  {
    type: 'beaconSales',
    itemName: 'Venta de beacons',
    icon: RiSignalTowerFill,
  },
  {
    type: 'storePickup',
    itemName: 'Retiro en tienda',
    icon: AiFillShop,
  },
  {
    type: 'QRSales',
    itemName: 'Venta de QR',
    icon: IoMdQrScanner,
  },
  {
    type: 'tireInflation',
    itemName: 'Inflado de neumaticos',
    icon: FaParking,
  },
];

export const BenefitTypes: {
  value: 'discount' | 'offer' | 'combo' | 'promo';
  label: string;
}[] = [
  {
    value: 'combo',
    label: 'Combo',
  },
  {
    value: 'discount',
    label: 'Descuento',
  },
  {
    value: 'offer',
    label: 'Oferta',
  },
  {
    value: 'promo',
    label: 'Promoción',
  },
];

export const BenefitStates: {
  value: 'active' | 'inactive' | 'paused';
  label: string;
  color: string;
}[] = [
  {
    value: 'active',
    label: 'Activo',
    color: 'green',
  },
  {
    value: 'inactive',
    label: 'Inactivo',
    color: 'red',
  },
  {
    value: 'paused',
    label: 'Pausado',
    color: 'yellow',
  },
];

export const HomeSlides = [
  {
    image: slidesImages[0],
    image_mobile: 'slides-images/mobile/2.png',
    text: `Registra tu Bikleta y forma<br /> parte de nuestra comunidad`,
    location: 'top-left',
    mobileLocation: 'bottom-left',
    tags: ['#salud', '#seguridad', '#sustentabilidad'],
  },
  {
    image: slidesImages[1],
    image_mobile: 'slides-images/mobile/1.png',
    text: `Únete a la movilidad segura y<br /> sustentable de la comunidad<br /> Bikleta`,
    text_mobile: `Se parte de Bikleta y unete a esta <br /> <strong>comunidad</strong> para cuidarnos <br> entre todos.`,
    location: 'bottom-left',
    tags: ['#salud', '#seguridad', '#sustentabilidad'],
  },
  {
    image: slidesImages[2],
    image_mobile: 'slides-images/mobile/3.png',
    text: `REGISTRATE como BIKLETA <br /> POINT y conoce nuestros <br />beneficios`,
    location: 'bottom-left',
    tags: ['#salud', '#seguridad', '#sustentabilidad'],
  },
];

export const stateOptions: {
  value: BenefitsType['status'];
  label: string;
}[] = [
  {
    value: 'active',
    label: 'Activo',
  },
  {
    value: 'inactive',
    label: 'Inactivo',
  },
  {
    value: 'paused',
    label: 'Pausado',
  },
];

export const typeOptions: {
  value: BenefitsType['type'];
  label: string;
}[] = [
  {
    value: 'discount',
    label: 'Descuento',
  },
  {
    value: 'promo',
    label: 'Promoción',
  },
];
