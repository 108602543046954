import {
    Box,
    FormControl,
    FormLabel,

} from "@chakra-ui/react";
import './styles.css';
import { Field } from 'formik';
import { countries } from "../../../resources/data/countries";


interface ImputPros {
    dataInput: any,
    styling?: any
    filetype: any
}


const CustomField = ({ dataInput, styling, filetype }: ImputPros) => {
    return (
        <Box style={styling?.container}>
            <FormControl
                position="relative"
                mt={10}
            >
                <Field
                    as={filetype}
                    type="text"
                    name={dataInput.name}
                    id={dataInput.name}
                    autoComplete={dataInput.name}
                    mt={1}
                    shadow="sm"
                    size="md"
                    w="full"
                    rounded="md"
                    variant="outline"
                    _placeholder={{ opacity: 0 }}
                    borderColor="#79747E"
                    zIndex={1}
                >
                    {dataInput.name === 'phoneRegion' ?
                        <>
                            {countries.map((country, index) => {
                                return <option key={index} value={`+${country.number}`}>{`${country.code} (+${country.number})`}</option>
                            })}
                        </>
                        : null
                    }
                </Field>
                <FormLabel
                    style={styling?.label}
                    pointerEvents="none"
                    htmlFor="name"
                    _dark={{
                        color: 'gray.50',
                    }}>
                    {dataInput.text}
                </FormLabel>
            </FormControl>
        </Box>
    );
}

export default CustomField;