import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image } from "@chakra-ui/react";

interface Props {
  items: string[];
}

export const Carousel = ({ items }: Props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  return (
    <div className="slider-container">
      {items.length > 4
        ? (
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index}>
                <Box>
                  <Image
                    mt={5}
                    key={index}
                    src={item}
                    alt={item}
                    borderRadius={20}
                    w={240}
                    h={140}
                    objectFit="cover"
                  />
                </Box>
              </div>
            ))}
          </Slider>)
        : (
          <Box w="100%" display="flex">
            {items.map((item, index) => (
              <Image
                mt={5}
                mr={7}
                key={index}
                src={item}
                alt={item}
                borderRadius={20}
                w={240}
                h={140}
                objectFit="cover"
              />
            ))}
          </Box>
        )}
    </div>
  );
}